import {
  IonAvatar,
  IonContent,
  IonIcon,
  IonImg,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
  IonNote,
  IonToolbar,
} from '@ionic/react';

import { useLocation } from 'react-router-dom';
import { addSharp, homeOutline, logoGooglePlaystore, logOutOutline, logOutSharp, mailOutline, mailSharp, notificationsOutline, notificationsSharp, paperPlaneOutline, paperPlaneSharp, personOutline, personSharp, phonePortraitOutline, phonePortraitSharp, trashOutline, trashSharp, warningOutline, warningSharp } from 'ionicons/icons';
import '../../../components/Menu.css';
import React from 'react';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../State/userstate';
import { Person } from '../../../components/types';
import { local_icons } from '../../../images/images';

interface AppPage {
  url: string;
  iosIcon: string;
  mdIcon: string;
  title: string;
  href?: boolean
}

const sample_image = `https://sunrift.com/wp-content/uploads/2014/12/Blake-profile-photo-square.jpg`

const appPages: AppPage[] = [
  {
    title: 'Summary',
    url: '/home',
    iosIcon: homeOutline,
    mdIcon: homeOutline
  },
  {
    title: 'Questions',
    url: '/questions',
    iosIcon: local_icons.question,
    mdIcon: local_icons.question
  },
  {
    title: 'Solutions',
    url: '/solutions',
    iosIcon: local_icons.answer,
    mdIcon: local_icons.answer
  },
  {
    title: 'Account',
    url: '/account',
    iosIcon: personOutline,
    mdIcon: personSharp
  },
  {
    title: 'Notifications',
    url: '/notifications',
    iosIcon: notificationsOutline,
    mdIcon: notificationsSharp
  },
  {
    title: 'Contact Us',
    url: '/contact',
    iosIcon: paperPlaneOutline,
    mdIcon: paperPlaneSharp
  },
  {
    title: 'New',
    url: '/create',
    iosIcon: addSharp,
    mdIcon: addSharp
  },
  {
    title: 'Get Quesers',
    url: 'https://play.google.com/store/apps/details?id=io.ionic.com.app.com.app.quesers&hl=en&gl=US',
    href: true,
    iosIcon: logoGooglePlaystore,
    mdIcon: logoGooglePlaystore
  },

  {
    title: 'Logout',
    url: 'lLogin',
    iosIcon: logOutOutline,
    mdIcon: logOutSharp
  }
];
 

const QuestionsUserMenu: React.FC = () => {
  const location = useLocation();

  const user: Person = useSelector(selectUser)

  return (
    <IonMenu side={`start`} menuId="main" contentId='main' type="overlay">
      <IonContent>
        <IonList lines={`none`} id="inbox-list">
          <IonToolbar>
            <IonAvatar style={{ margin: `auto` }}>
              <IonImg src={user.photoURL || sample_image}></IonImg>
            </IonAvatar>
          </IonToolbar>
          <IonListHeader>Quesers</IonListHeader>
          <IonNote>Community Platform</IonNote>
          {appPages.map((appPage, index) => {
            let _color:string = "";
            if( appPage.title === "question" || appPage.title ==="answer"){
              _color = "medium"
            }
            return (
              <IonMenuToggle key={index} autoHide={false} >
                {
                  appPage.href ? <IonItem className={location.pathname === appPage.url ? 'selected' : ''} target='__blank' href={appPage.url} routerDirection="none" lines="none" detail={false}>
                    <IonIcon slot="start" ios={appPage.iosIcon} md={appPage.mdIcon} color={_color}/>
                    <IonLabel>{appPage.title}</IonLabel>
                  </IonItem> :
                    <IonItem className={location.pathname === appPage.url ? 'selected' : ''} routerLink={appPage.url} routerDirection="none" lines="none" detail={false}>
                      <IonIcon slot="start" ios={appPage.iosIcon} md={appPage.mdIcon}  color={_color} />
                      <IonLabel>{appPage.title}</IonLabel>
                    </IonItem>
                }
              </IonMenuToggle>
            );
          })}
        </IonList>
      </IonContent>
    </IonMenu>
  );
};

export default QuestionsUserMenu;
