import { UserDocument } from "../components/types"

interface Action {
    payload: any,
    type: `update_paper`
}


const initial_state: UserDocument[] = []

function updatePapers(payload:  UserDocument[]): Action {
    console.log(payload)
    return (
        {
            type: `update_paper`,
            payload
        }
    )
}


function paperReducer(state = initial_state, action: Action): (UserDocument[]) {

    if (action.type == `update_paper`) return action.payload
    else
        return state
}

export const selectPapers = (state:any) => state.paperReducer

export  {updatePapers};

export default paperReducer



