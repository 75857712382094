import { fstore } from ".";
import { AdminPerson, Person } from "../components/types";


export async function getPerson(email: string): Promise<(Person | undefined)> {
    const doc: any = (await fstore.collection(`people`).doc(email).get()).data()
    if (doc?.email) {
        return doc
    }
    return undefined
}

export async function createPerson(user:Person): Promise<void> {
    
     await fstore.collection(`people`).doc(user.email).set(user)
   
}


export async function getAdmin(email: string): Promise<(AdminPerson | undefined)> {
    const doc: any = (await fstore.collection(`admin`).doc(email).get()).data()
    if (doc?.email) {
        return doc
    }
    return undefined
}


export async function updateAdminRecord(admin:AdminPerson,obj:any): Promise<void> {
    
    await fstore.collection(`people`).doc(admin.email).update(obj)
  
}
