import {
  IonAvatar,
  IonBadge,
  IonButton,
  IonButtons,
  IonCard,
  IonCardHeader,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonImg,
  IonMenuButton,
  IonPage,
  IonRippleEffect,
  IonRow,
  IonSpinner,
  IonTitle,
  IonToolbar,
  useIonViewWillEnter,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import shape from "../images/p2.jpg";
import { logOut } from "ionicons/icons";
import { auth, fstore } from "../../../../Firebase";
import { tryCatch } from "../../../../Firebase/admin";
import {
  AdminPerson,
  Person,
  QDocument,
  QPaymentRequest,
  UserDocument,
} from "../../../../components/types";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { selectAdmin, updateAdmin } from "../../../../State/adminState";
import { getLocalAdmin } from "../../../../State/local";
import { Storage } from "@capacitor/storage";
import { createNewDocField } from "../../../../Firebase/adaptdb";

const AdminHome: React.FC = () => {
  const [papers, setpapers] = useState<QDocument[]>([]);
  const [pending, setpending] = useState<UserDocument[]>([]);
  const [rejected, setrejected] = useState<UserDocument[]>([]);
  const [approved, setapproved] = useState<UserDocument[]>([]);
  const [assigned, setassigned] = useState<UserDocument[]>([]);
  const [unAssigned, setunAssigned] = useState<UserDocument[]>([]);
  const [uploader, setuploader] = useState<Person[]>([]);
  const [lenghts, setlenghts] = useState<number[]>([]);
  const [paymentRequests, setpaymentRequests] = useState<QPaymentRequest[]>([]);
  const [loading, setloading] = useState(false);
  const admin: AdminPerson = useSelector(selectAdmin);
  const dispatch = useDispatch();
  const history = useHistory();

  async function initAdmin() {
    if (!admin.email) {
      const p = await getLocalAdmin();
      console.log(p);
      if (p) {
        dispatch(updateAdmin(p));
      } else {
        history.push(`/admin/login`);
      }
    }
  }

  useIonViewWillEnter(() => {
    initAdmin();
  });
  useEffect(() => {
    getApproved();
    getRejected();
    getPending();
    getPaymentRequest();
    getAssigned();
    getUnAssigned();
    getUploaders();
    getLengths();
  }, []);

  function getPapers() {
    tryCatch(async () => {
      setloading(true);
      fstore
        .collection(`faculties`)
        .doc(`FET`)
        .collection(`papers`)
        .onSnapshot((documents) => {
          const docs: any[] = documents.docs.map((paper, index) => {
            let t: any[] = [];
            if (paper.data().solution !== true) {
              t.push(paper.data());
            }
            return t;
          });
          setpapers(docs);
          setloading(false);
        });
    });
  }

  async function getPending() {
    setloading(true);
    fstore
      .collection(`faculties`)
      .doc(`FET`)
      .collection(`pending`)
      .onSnapshot((snapshot) => {
        const docs: any[] = snapshot.docs.map((doc) => {
          let t = [];
          if (!doc.data().solution) {
            t.push(doc.data());
          }
          return t;
        }); // will return only question papers

        if (docs[0][0].temp) {
          setpending([]);
          setloading(false);
        } else {
          setpending(docs[0]);
          setloading(false);
        }
      });
  }

  async function getRejected() {
    setloading(true);
    fstore
      .collection(`faculties`)
      .doc(`FET`)
      .collection(`rejected`)
      .onSnapshot((snapshot) => {
        const docs: any[] = snapshot.docs.map((doc) => doc.data());
        setrejected(docs);
        setloading(false);
      });
  }

  async function getApproved() {
    setloading(true);
    fstore
      .collection(`faculties`)
      .doc(`FET`)
      .collection(`approved`)
      .onSnapshot((snapshot) => {
        const docs: any[] = snapshot.docs.map((doc) => doc.data());
        setapproved(docs);
        setloading(false);
      });
  }

  useEffect(() => {
    getPapers();
  }, []);

  async function getPaymentRequest() {
    setloading(true);
    fstore
      .collection(`faculties`)
      .doc(`FET`)
      .collection(`payment-requests`)
      .onSnapshot((snapshot) => {
        const docs: any[] = snapshot.docs.map((doc) => doc.data());
        setpaymentRequests([...docs]);
        setloading(false);
      });
  }

  async function getAssigned() {
    setloading(true);
    fstore
      .collection(`faculties`)
      .doc(`FET`)
      .collection(`assigned`)
      .where("assigned", "==", true)
      .onSnapshot((snapshot) => {
        const docs: any[] = snapshot.docs.map((doc) => doc.data());
        setassigned([...docs]);
        if (docs.length == 0) {
          setassigned([]);
        }
        setloading(false);
      });
  }

  async function getUploaders() {
    setloading(true);
    fstore
      .collection(`uploader`)
      .where("faculty", "==", "FET")
      .onSnapshot((snapshot) => {
        const docs: any[] = snapshot.docs.map((doc) => doc.data());
        setuploader([...docs]);
        if (docs.length == 0) {
          setuploader([]);
        }
        setloading(false);
      });
  }

  async function getLengths() {
    setloading(true);
    let temp: number[] = [];
    for (let i = 0; i < uploader.length; i++) {
      fstore
        .collection("uploader")
        .doc(uploader[i].email)
        .collection("papers")
        .onSnapshot((snapshot) => {
          let docsLength: any = snapshot.docs.length - 1;
          temp.push(docsLength);
        });
      if (i === uploader.length - 1) setlenghts(temp);
    }
    setloading(false);
  }

  async function getUnAssigned() {
    setloading(true);
    fstore
      .collection(`faculties`)
      .doc(`FET`)
      .collection(`unassigned`)
      .where("assigned", "==", false)
      .onSnapshot((snapshot) => {
        const docs: any[] = snapshot.docs.map((doc) => doc.data());
        setunAssigned([...docs]);
        if (docs.length == 0) {
          setunAssigned([]);
        }
        setloading(false);
      });
  }
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="dark">
          <IonButtons slot="start">
            <IonMenuButton menu="admin"></IonMenuButton>
          </IonButtons>
          <IonTitle slot="start">QCP Admin</IonTitle>
          <IonButton routerLink={`/admin/papers`} fill={`clear`} slot={`end`}>
            papers{" "}
            <IonBadge className={`ion-margin-start`} color={`tertiary`}>
              {papers.length}
            </IonBadge>
          </IonButton>
          {!loading ? (
            <IonAvatar slot={"end"} className="p-2 ">
              {admin.photoURL && (
                <img
                  alt=""
                  src={admin.photoURL}
                  className="d-sm-block d-xs-none d-lg-block d-md-block"
                ></img>
              )}
            </IonAvatar>
          ) : (
            <IonSpinner></IonSpinner>
          )}
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonGrid className="container-lg">
          <IonRow className="container-fluid">
            <IonCol sizeLg="4">
              <IonCard
                button
                onClick={() => history.push(`/admin/approved`, approved)}
                className="w-100"
                color="tertiary"
              >
                <IonCardHeader>
                  <IonToolbar color="tertiary">
                    <IonTitle className="text-center">Approved Papers</IonTitle>
                  </IonToolbar>
                  <IonToolbar color="tertiary">
                    <IonTitle className="text-center h1 display-1">
                      {" "}
                      {loading && <IonSpinner></IonSpinner>}{" "}
                      {!loading ? approved.length : ``}
                    </IonTitle>
                  </IonToolbar>
                </IonCardHeader>
              </IonCard>
            </IonCol>
            <IonCol sizeLg="4">
              <IonCard
                className="w-100"
                color="warning"
                onClick={() => history.push(`/admin/pending`, pending)}
              >
                <IonCardHeader>
                  <IonToolbar color="warning">
                    <IonTitle className="text-center">Pending Reviews</IonTitle>
                  </IonToolbar>
                  <IonToolbar color="warning">
                    <IonTitle className="text-center h1 display-1">
                      {loading && <IonSpinner></IonSpinner>}{" "}
                      {!loading ? pending.length : ``}
                    </IonTitle>
                  </IonToolbar>
                </IonCardHeader>
              </IonCard>
            </IonCol>
            <IonCol sizeLg="4">
              <IonCard
                onClick={() => history.push(`/admin/rejected`, rejected)}
                className="w-100"
                color="danger"
                button
              >
                <IonCardHeader>
                  <IonToolbar color="danger">
                    <IonTitle className="text-center">Rejected Papers</IonTitle>
                  </IonToolbar>
                  <IonToolbar color="danger">
                    <IonTitle className="text-center h1 display-1">
                      {" "}
                      {loading && <IonSpinner></IonSpinner>}{" "}
                      {!loading ? rejected.length : ``}
                    </IonTitle>
                  </IonToolbar>
                </IonCardHeader>
              </IonCard>
            </IonCol>
            <IonCol sizeLg="4">
              <IonCard
                className="w-100"
                color="success"
                onClick={() =>
                  history.push("/admin/payment-requests", paymentRequests)
                }
              >
                <IonCardHeader>
                  <IonToolbar color="success">
                    <IonTitle className="text-center">
                      Payment Requests
                    </IonTitle>
                  </IonToolbar>
                  <IonToolbar color="success">
                    <IonTitle className="text-center h1 display-1">
                      {loading && <IonSpinner></IonSpinner>}{" "}
                      {!loading ? paymentRequests.length : ``}
                    </IonTitle>
                  </IonToolbar>
                </IonCardHeader>
              </IonCard>
            </IonCol>

            {/*  */}
            <IonCol sizeLg="4">
              <IonCard
                className="w-100"
                color="secondary"
                onClick={() =>
                  history.push("/admin/assigned", {
                    assigned: assigned,
                    uploader: uploader,
                  })
                }
              >
                <IonCardHeader>
                  <IonToolbar color="secondary">
                    <IonTitle className="text-center">Assigned Papers</IonTitle>
                  </IonToolbar>
                  <IonToolbar color="secondary">
                    <IonTitle className="text-center h1 display-1">
                      {loading && <IonSpinner></IonSpinner>}{" "}
                      {!loading ? assigned.length : ``}
                    </IonTitle>
                  </IonToolbar>
                </IonCardHeader>
              </IonCard>
            </IonCol>
            <IonCol sizeLg="4">
              <IonCard
                className="w-100"
                color="medium"
                onClick={() =>
                  history.push("/admin/unassigned", {
                    unassigned: unAssigned,
                    uploader: uploader,
                  })
                }
              >
                <IonCardHeader>
                  <IonToolbar color="medium">
                    <IonTitle className="text-center">
                      Unassigned Papers
                    </IonTitle>
                  </IonToolbar>
                  <IonToolbar color="medium">
                    <IonTitle className="text-center h1 display-1">
                      {loading && <IonSpinner></IonSpinner>}{" "}
                      {!loading ? unAssigned.length : ``}
                    </IonTitle>
                  </IonToolbar>
                </IonCardHeader>
              </IonCard>
            </IonCol>
            <IonCol sizeLg="8">
              <IonCard
                className="w-100"
                color="light"
                onClick={() =>
                  history.push("/admin/uploader", {
                    uploader: uploader,
                    assigned: assigned,
                    lenghts: lenghts,
                  })
                }
              >
                <IonCardHeader>
                  <IonToolbar color="light">
                    <IonTitle className="text-center">Uploaders</IonTitle>
                  </IonToolbar>
                  <IonToolbar color="light">
                    <IonTitle className="text-center h1 display-1">
                      {loading && <IonSpinner></IonSpinner>}{" "}
                      {!loading ? uploader.length : ``}
                    </IonTitle>
                  </IonToolbar>
                </IonCardHeader>
              </IonCard>
            </IonCol>
            <IonCol sizeLg="4">
              <IonCard
                className="w-100"
                color="dark"
                onClick={() => {
                  history.push(`/admin/login`);
                  Storage.remove({ key: `admin` });
                  auth.signOut();
                }}
              >
                <IonCardHeader>
                  <IonToolbar color="dark">
                    <IonTitle className="text-center">Logout</IonTitle>
                  </IonToolbar>
                  <IonToolbar color="dark">
                    <IonTitle className="text-center  display-1">
                      <IonIcon icon={logOut}></IonIcon>
                    </IonTitle>
                  </IonToolbar>
                </IonCardHeader>
                <IonRippleEffect type="unbounded"></IonRippleEffect>
              </IonCard>
            </IonCol>
          </IonRow>
        </IonGrid>
        {/* <IonButton onClick={()=>{
              createNewDocField();
            }}>refactor db</IonButton> */}
      </IonContent>
    </IonPage>
  );
};

export default AdminHome;
