import { IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonCol, IonContent, IonFab, IonFabButton, IonGrid, IonHeader, IonIcon, IonImg, IonItem, IonLabel, IonList, IonLoading, IonMenu, IonMenuButton, IonPage, IonProgressBar, IonRow, IonTitle, IonToolbar } from '@ionic/react'
import { callOutline, chevronBackOutline, logoWhatsapp, mailOutline } from 'ionicons/icons'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router'
import { Link } from 'react-router-dom'
import { Person, UserDocument } from '../../../components/types'
import { fstore, storage } from '../../../Firebase'
import { tryCatch } from '../../../Firebase/admin'
import { local_images } from '../../../images/images'
import { getUser } from '../../../State/local'
import { selectPapers } from '../../../State/papersState'
import { selectUser, updateUser } from '../../../State/userstate'

function PaperDetail() {
    const [document, setdocument] = useState<UserDocument>()
    const [loading, setloading] = useState(false)
    const [balance, setbalance] = useState(0)
    const location = useLocation()
    const user: Person = useSelector(selectUser)
    const history = useHistory()
    const dispatch = useDispatch()


    function openFiles() {
        const a = window.document.createElement(`a`)
        a.target = `__blank`
        document?.files.map(url => {
            a.href = url;
            const k = url != `` ? a.click() : ``;
        })
    }
    useEffect(() => {
        const temp: any = location.state
        setdocument(temp)
        initUser()

    }, [])


    async function initUser() {
        if (!user.email) {
            const u = await getUser()
            if (u) {
                dispatch(updateUser(u))
            } else {
                history.push(`/home`)
            }
        }
    }

    async function edit() {
        if (!document || !document.id) {
            return;
        }
        setloading(true)
        await fstore.collection(`faculties`)
            .doc(`FET`)
            .collection(`papers`)
            .doc(document.id)
            .get().then((res) => {
                const data: any = res.data()
                if (data) {
                    if (data.available == true || document.status == 'pending') {
                        history.push(`/Create/upload`, [data]);
                    } else {
                        alert('The paper has already been submitted by someone else');
                    }
                } else {
                    alert('no info about paper ' + document.id)
                }
            }).catch(alert)
        setloading(false)
    }
    async function deletePaper() {
        console.log(user.email)

        if (document?.id && user.email) {
            tryCatch(async () => {
                setloading(true)
                await fstore.collection(`faculties`)
                    .doc(`FET`)
                    .collection(`papers`)
                    .doc(document.id)
                    .update({ available: true }).catch(console.log);

                await fstore.collection(`people`)
                    .doc(user.email)
                    .collection(`submitted`)
                    .doc(document.id)
                    .delete().catch(console.log)
                // setloading(false)
                // const a = window.document.createElement('a')
                // a.href = "/home"
                history.goBack()
                // a.click()


            })

        }
    }



    return (
        <IonPage>
            <IonHeader>
                <IonToolbar color={`dark`}>
                    <IonTitle>{document?.code}  {document?.title} {document?.type} {document?.year}</IonTitle>
                    <IonMenuButton slot={`end`} ></IonMenuButton>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonLoading isOpen={loading} onDidDismiss={() => setloading(false)} spinner='circles' message=''></IonLoading>
                <IonGrid className={`ion-padding`}>
                    <IonRow>
                        <IonCol ></IonCol>
                        <IonCol style={{ textAlign: `center`, paddingRight: `20px` }} size={`12`} sizeSm={`5`} sizeMd={`4`} sizeLg={`3`} sizeXl={`4`}>
                            <div >
                                {loading && <IonProgressBar type={`indeterminate`}></IonProgressBar>}
                                <IonImg className="card" style={{ height: `60vh` }} src={local_images.pdfblur} alt="" />
                            </div>
                        </IonCol>
                        <IonCol size={`6`} sizeSm={`5`} sizeMd={`5`} sizeLg={`6`} sizeXl={`5`}>
                            <IonList>
                                <IonItem color={`none`} lines={`none`}>
                                    {document?.code}  {document?.title} {document?.type} {document?.year}
                                </IonItem>
                                <IonItem color={`none`} lines={`none`}>
                                    {document?.faculty}
                                </IonItem>
                                <IonItem color={`none`} lines={`none`}>
                                    {/* Submitted 3 times */}
                                </IonItem>
                                <IonItem color={`none`} lines={`none`}>
                                    {document?.files.filter(file => file != ``).length} files
                                </IonItem>
                                <IonItem color={`none`} lines={`none`}>
                                    <IonLabel color={document?.status == `pending` ? "warning" : document?.status == `approved` ? `success` : `danger`}>  {document?.status}</IonLabel>
                                </IonItem>
                                <IonItem color={`none`} lines={`none`}>
                                    <IonLabel color={`medium`}>worth 50 FCFA</IonLabel>
                                </IonItem>
                                <IonButton onClick={openFiles} fill={`outline`} color={`dark`}>Open Up Files</IonButton>
                                <IonItem color={`none`} lines={`none`}>
                                    <IonButtons slot={`end`}>
                                        <IonButton onClick={() => edit()} color={`success`} mode={`ios`} > <Link to={`#`} >Edit</Link ></IonButton>
                                        <IonButton onClick={() => deletePaper()} color={`success`} mode={`ios`} >  <Link to={`#`}>Delete</Link></IonButton>
                                    </IonButtons>
                                </IonItem>
                            </IonList>

                        </IonCol>
                        <IonCol></IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <br />
                            <br />
                            {document?.message && <IonToolbar className={``}>
                                <IonCard className={`card`} color={`light`} mode={`ios`}>
                                    <IonToolbar mode={`md`} className={`ion-padding-horizontal`}>
                                        <IonButtons className={`ion-margin-end`} slot={`start`}>
                                            <IonButton>
                                                <IonIcon icon={mailOutline}></IonIcon>
                                            </IonButton>
                                        </IonButtons>
                                        <IonCardTitle>
                                            Review Message
                                        </IonCardTitle>
                                    </IonToolbar>
                                    <IonCardContent>
                                        <div className="ion-padding">
                                            {document.message}
                                        </div>
                                    </IonCardContent>
                                </IonCard>
                            </IonToolbar>}
                        </IonCol>
                    </IonRow>
                </IonGrid>
                <IonToolbar className={`ion-padding ion-margin`}>
                    <IonButtons className={`row`} slot={`end`}>
                        <IonButton href={`mailto:quesersteam@gmail.com`} target={`__blank`} className={`col`}  >
                            <IonIcon slot={`start`} color={`danger`} icon={mailOutline}></IonIcon>
                            <IonLabel>send us a mail</IonLabel>
                        </IonButton>
                        <IonButton href={`https://chat.whatsapp.com/LchBn6cw40YI7VL6G8Om2r`} target={`__blank`} className={`col`}>
                            <IonIcon slot={`start`} color={`success`} icon={logoWhatsapp}></IonIcon>
                            <IonLabel>Message Us</IonLabel>
                        </IonButton>
                        <IonButton className={`col`}>
                            <IonIcon slot={`start`} color={`tertiary`} icon={callOutline}></IonIcon>
                            <IonLabel>call Us</IonLabel>
                        </IonButton>
                    </IonButtons>
                    <IonLoading message={`Hold on...`} onDidDismiss={() => setloading(false)} isOpen={loading}></IonLoading>
                </IonToolbar>
            </IonContent>
        </IonPage>
    )
}

export default PaperDetail
