import React, { useEffect } from "react";
import {
  IonApp,
  IonRouterOutlet,
  IonSplitPane,
  setupIonicReact,
} from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { Redirect, Route } from "react-router-dom";
import Menu from "../pages/questions/user/Menu";
import Page from "../pages/questions/user/Page";
import Login from "../pages/questions/user/Login";
import QCPClient from "../pages/questions/user/QCPClient";
import UploadPaper from "../pages/questions/user/UploadPaper";
import UploadTaskPage from "../pages/questions/user/UploadTaskPage";
import Notifications from "../pages/questions/user/Notifications";
import Account from "../pages/questions/user/Account";
import PaperDetail from "../pages/questions/user/PaperDetail";
import LandingPage from "../pages/LandingPage";
import { getUser } from "../State/local";
import { useDispatch } from "react-redux";
import { updateUser } from "../State/userstate";
import PaymentRequest from "../pages/questions/admin/pages/PaymentRequest";
import Pending from "../pages/questions/admin/pages/Pending";
import Approved from "../pages/questions/admin/pages/Approved";
import Rejected from "../pages/questions/admin/pages/Rejected";
import AdminLogin from "../pages/questions/admin/pages/Login";
import AdminHome from "../pages/questions/admin/pages/Home";
import Contact from "../pages/contact";
import AdminPapers from "../pages/questions/admin/pages/Papers";
import AllQuestions from "../pages/questions/user/Questions";
import AllSolutions from "../pages/questions/user/Solutions";
import ReviewAdminLogin from "../pages/answers/review/pages/Login";
import ReviewAdminPending from "../pages/answers/review/pages/Pending";
import ReviewAdminApproved from "../pages/answers/review/pages/Approved";
import ReviewAdminRejected from "../pages/answers/review/pages/Rejected";
import ReviewAdminHome from "../pages/answers/review/pages/Home";
import ReviewAdminPapers from "../pages/answers/review/pages/Papers";
import Choice from "../pages/Choice";
import UploadPaperSolution from "../pages/questions/user/UploadPaperSolution";
import UploadSolutionTaskPage from "../pages/questions/user/UploadSolutionTaskPage";
import UnAssigned from "../pages/questions/admin/pages/UnAssigned";
import Assigned from "../pages/questions/admin/pages/Assigned";
import Uploaders from "../pages/questions/admin/pages/Uploader";

function Routes() {
  const dispatch = useDispatch();

  useEffect(() => {
    getUserInfo();
  }, []);

  async function getUserInfo() {
    const user = await getUser();
    if (user && user.email) {
      dispatch(updateUser(user));
    }
  }
  return ( 
    <IonRouterOutlet id="main">
      <Route path="/page/:name" exact={true}>
        <Page />
      </Route>
      <Route path="/login" exact={true}>
        <Login />
      </Route>
      <Route path="/create/questions" exact={true}>
        <UploadPaper />
      </Route>
      <Route path="/create/solutions" exact={true}>
        <UploadPaperSolution />
      </Route>
      <Route path="/create/questions/upload" exact={true}>
        <UploadTaskPage />
      </Route>
      <Route path="/create/solutions/upload" exact={true}>
        <UploadSolutionTaskPage />
      </Route>
      <Route path="/home" exact={true}>
        <QCPClient />
      </Route>
      <Route path="/questions" exact={true}>
        <AllQuestions></AllQuestions>
      </Route>
      <Route path="/solutions" exact={true}>
        <AllSolutions></AllSolutions>
      </Route>
      <Route path="/notifications" exact={true}>
        <Notifications />
      </Route>
      <Route path="/account" exact={true}>
        <Account />
      </Route>
      <Route path="/paper-detail" exact={true}>
        <PaperDetail />
      </Route>
      <Route path="/" exact={true}>
        <LandingPage />
      </Route>
      <Route path="/contact" exact={true}>
        <Contact />
      </Route>

      {/* Question Uplaod Admin Routes */}
      <Route path={`/admin/payment-requests`} exact={true}>
        <PaymentRequest></PaymentRequest>
      </Route>
      <Route path={"/admin/pending"} exact={true}>
        <Pending></Pending>
      </Route>
      <Route path={"/admin/approved"} exact={true}>
        <Approved></Approved>
      </Route>
      <Route path={"/admin/rejected"} exact={true}>
        <Rejected></Rejected>
      </Route>
      <Route path={"/admin/assigned"} exact={true}>
        <Assigned></Assigned>
      </Route>
      <Route path={"/admin/unassigned"} exact={true}>
        <UnAssigned></UnAssigned>
      </Route>
      <Route path={"/admin/login"} exact={true}>
        <AdminLogin></AdminLogin>
      </Route>
      <Route path={"/admin/uploader"} exact={true}>
        <Uploaders></Uploaders>
      </Route>
      {/* <Route path={"/admin/"} exact={true}>
          <AdminHome></AdminHome>
        </Route> */}
      <Route path={"/admin/home"} exact={true}>
        <AdminHome></AdminHome>
      </Route>
      <Route path={"/admin/papers"} exact={true}>
        <AdminPapers></AdminPapers>
      </Route> 
       {/* Answer Upload Admin Routes */} 
       <Route path={"/review/pending"} exact={true}>
        <ReviewAdminPending/>
      </Route>
      <Route path={"/review/approved"} exact={true}>
        <ReviewAdminApproved/>
      </Route>
      <Route path={"/review/rejected"} exact={true}>
        <ReviewAdminRejected/>
      </Route>
      <Route path={"/review/login"} exact={true}> 
        <ReviewAdminLogin/>
      </Route>
      <Route path={"/review/home"} exact={true}>
        <ReviewAdminHome/>
      </Route> 
      {/* <Route path={"/admin"}>
        <Redirect to="/admin/home"></Redirect>
      </Route> 
      <Route path={"/review"}>
        <Redirect to="/review/home"></Redirect>
      </Route>  */}
      <Route path={"/review/papers"} exact={true}>
        <ReviewAdminPapers/>
      </Route>

      {/* Choice */}
      <Route path={"/upload/choice"} exact={true}>
        <Choice/>
      </Route>
      
    </IonRouterOutlet> 
  );
}

export default Routes;
