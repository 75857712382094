import {
  IonAvatar,
  IonBackButton,
  IonButton,
  IonButtons,
  IonCardSubtitle,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonImg,
  IonItem,
  IonLabel,
  IonLoading,
  IonNote,
  IonPage,
  IonProgressBar,
  IonRow,
  IonSearchbar,
  IonSpinner,
  IonTitle,
  IonToolbar,
  useIonViewDidEnter,
} from "@ionic/react";
import {
  arrowUpOutline,
  chevronForward,
  close,
  trashOutline,
} from "ionicons/icons";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { Person, QDocument, UserDocument } from "../../../components/types";
import { fstore, storage } from "../../../Firebase";
import { local_images } from "../../../images/images";
import { FileUploader } from "react-drag-drop-files";
import { selectUser, updateUser } from "../../../State/userstate";
import { useDispatch, useSelector } from "react-redux";
import { tryCatch } from "../../../Firebase/admin";
import { getUser } from "../../../State/local";

export const sample_image = `https://sunrift.com/wp-content/uploads/2014/12/Blake-profile-photo-square.jpg`;

function UploadTaskPage() {
  const [selected, setselected] = useState<QDocument[]>([]);
  const [uploads, setuploads] = useState<UserDocument[]>([]);
  const [files, setfiles] = useState<(File | Blob)[]>([]);
  const [ready, setready] = useState(false);
  const [uploading, setuploading] = useState(false);
  const [loading, setloading] = useState(false);

  const history = useHistory();
  const location = useLocation();
  const user: Person = useSelector(selectUser);
  const dispatch = useDispatch();

  useEffect(() => {
    initHistory();
  }, [history.location.state]);

  useIonViewDidEnter(() => {
    setselected([...selected.filter((e) => e.available)]);
  });

  async function initHistory() {
    if (!user) {
      const u = await getUser();
      if (u?.email) {
        dispatch(updateUser(u));
      } else {
        alert(`user info does not exist`);
        history.push(`/login`);
      }
    }
    if (history.location.state) {
      const temp: any = history.location.state || [];

      const up_temp: UserDocument[] = temp.map((res: QDocument) => {
        const temp_res: UserDocument = {
          cost: res.cost,
          create_timestamp: Date.now(),
          department: res.department,
          faculty: res.department,
          files: [],
          status: `pending`,
          times_submitted: Date.now(),
          title: res.title,
          update_timestamp: Date.now(),
          user_email: user.email,
          user_name: user.name,
          user_tel: user.tel,
          user_photoURL: user.photoURL,
          message: ``,
          id: res.id,
          code: res.code,
          type: res.type,
          year: res.year,
        };
        return temp_res;
      });
      setuploads([...up_temp]);
    } else {
      setselected([]);
    }
  }

  function deleteThis(index: number) {
    const temp = uploads;
    temp.splice(index, 1);
    setuploads([]);
    setuploads([...temp]);
  }
  function deleteFile(index: number, fileIndex: number) {
    const u_temp = uploads;
    let temp: any[] = u_temp[index].blobs || [];
    temp.splice(fileIndex, 1);
    // temp = (u_temp[index].files || [])
    // temp.splice(fileIndex, 1);
    // temp = (u_temp[index].uploadProgress || [])
    // temp.splice(fileIndex, 1);
    setuploads([
      ...uploads.map((u, i) => {
        if (i == index) {
          return { ...u, blobs: temp };
        }
        return u;
      }),
    ]);
  }

  function updateUploads(index: number, file: File) {
    let temp = uploads[index];
    temp.blobs = [...(temp.blobs ? temp.blobs! : []), file];
    let temp_uploads = uploads;
    temp_uploads[index] = temp;
    setuploads([...temp_uploads]);
    setuploading(false);

    temp.uploadProgress = [...(temp.uploadProgress || []), 0];

    const reference = storage.ref(
      `users/${user.email}/uploads/${temp.id}/${file.size + file.name}`
    );
    reference.put(file).on(
      `state_changed`,
      (res) => {
        temp.uploadProgress![(temp.uploadProgress || [1])?.length - 1] =
          res.bytesTransferred / res.totalBytes - 0.0577;
        temp_uploads[index] = temp;
        console.log(temp.uploadProgress);
        setuploads([...temp_uploads]);
        if (res.bytesTransferred / res.totalBytes === 1) {
          setready(true);
          setuploading(true);
        } else {
          setready(false);
          setuploading(!true);
        }
      },
      (err) => {
        alert(err.message);
      },
      async () => {
        const url = await reference.getDownloadURL();
        temp.files = (temp.blobs || []).map((res) => {
          return ``;
        });
        temp.files[index] = url;
        temp.uploadProgress![(temp.uploadProgress || [1])?.length - 1] = 1;
        temp_uploads[index] = temp;
        setuploads([...temp_uploads]);
        setuploads([...temp_uploads]);
      }
    );
  }
  function uploadPapers() {
    const inComplete = uploads.filter(
      (res) => res.files.length == 0 || hasEmptyString(res.files)
    );

    if (inComplete.length > 0) {
      alert(
        `unable to submit because you have not uploaded any files for  ${inComplete[0].code} ${inComplete[0].title} ${inComplete[0].type} ${inComplete[0].year}`
      );
      const temp = uploads;
      return;
    } else {
      submitPapers();
    }
  }

  function hasEmptyString(arr: string[]) {
    const val = arr.filter((el) => el.length == 0);
    return false;
    return val.length > 0;
  }

  function submitPapers() {
    setloading(true);
    setuploading(true);
    tryCatch(async () => {
      setuploading(false);
      const queries = uploads.map((paper) => {
        const finalPaper: UserDocument = getFinalPaper(paper, user);
        console.log(finalPaper);
        if (!finalPaper.code) return new Promise((res, rej) => res(""));
        return fstore
          .collection(`people`)
          .doc(user.email)
          .collection(`submitted`)
          .doc(paper.id)
          .set(finalPaper);
      });

      const queries2 = uploads.map((res) => {
        if (!res) return new Promise((res, rej) => res(""));
        fstore
          .collection(`faculties`)
          .doc(`FET`)
          .collection(`papers`)
          .doc(res.id)
          .update({ available: false });
      });
      const queries3 = uploads.map((paper) => {
        const finalPaper: UserDocument = getFinalPaper(paper, user);
        if (!paper || !finalPaper) return new Promise((res, rej) => res(""));
        return fstore
          .collection(`faculties`)
          .doc(`FET`)
          .collection(`pending`)
          .doc(paper.id)
          .set(finalPaper);
      });
      Promise.all([...queries, ...queries2, ...queries3])
        .catch(console.log)
        .finally(() => {
          setuploading(false);
          history.push(`/home`);
          setloading(false);
        });
    });
  }
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color={`dark`}>
          <IonButtons slot="start">
            <IonBackButton mode="md"></IonBackButton>
          </IonButtons>
          <IonTitle>Upload Papers</IonTitle>
          {user.photoURL && (
            <IonAvatar style={{ transform: `scale(0.8)` }} slot={`end`}>
              <IonImg src={user.photoURL}></IonImg>
            </IonAvatar>
          )}
        </IonToolbar>
        {loading && <IonProgressBar type={`indeterminate`}></IonProgressBar>}
      </IonHeader>
      <IonLoading
        spinner={`circular`}
        message={`uploading`}
        onDidDismiss={() => setloading(false)}
        isOpen={loading}
      ></IonLoading>
      <IonContent>
        <IonToolbar></IonToolbar>
        <IonToolbar>
          <IonGrid>
            <IonRow></IonRow>
            <IonRow>
              <IonCol></IonCol>
              <IonCol
                sizeSm={`11`}
                sizeMd={`9`}
                sizeLg={`8`}
                sizeXl={`7`}
                size={`12`}
              >
                {uploads.map((res, index) => {
                  return (
                    <div
                      key={index}
                      className="card ion-margin-vertical container-fluid"
                    >
                      <IonToolbar>
                        <IonCardSubtitle className="ion-text-capitalize">
                          {res.code} {res.title} {res.type} {res.year}
                        </IonCardSubtitle>
                        {/* <IonCheckbox slot={`end`}></IonCheckbox> */}
                        <IonButtons slot={`end`}>
                          <IonButton onClick={() => deleteThis(index)}>
                            <IonIcon icon={trashOutline}></IonIcon>
                          </IonButton>
                        </IonButtons>
                      </IonToolbar>
                      <IonRow>
                        {res.blobs?.map((val: File | any, index2: number) => (
                          <IonCol key={index2} size={`6`} sizeSm={`4`}>
                            <div
                              style={{ position: `relative`, height: `90%` }}
                              className="card ion-padding text-left"
                            >
                              <IonButton
                                onClick={() => deleteFile(index, index2)}
                                disabled={loading}
                                color={`dark`}
                                style={{
                                  position: `absolute`,
                                  top: `2px`,
                                  right: `6px`,
                                }}
                                size={`small`}
                                fill={`clear`}
                              >
                                <IonIcon icon={close}></IonIcon>
                              </IonButton>
                              <div style={{ height: `20px` }}></div>
                              <div>
                                <small>{val.name}</small>
                              </div>
                              <div>
                                <IonProgressBar
                                  color={`success`}
                                  type={
                                    ((res.uploadProgress || []).length > 0
                                      ? res.uploadProgress![index]
                                      : 0) > 0
                                      ? "determinate"
                                      : "indeterminate"
                                  }
                                  value={
                                    (res.uploadProgress || []).length > 0
                                      ? res.uploadProgress![index]
                                      : 0
                                  }
                                ></IonProgressBar>
                              </div>
                            </div>
                          </IonCol>
                        ))}
                      </IonRow>{" "}
                      <DragDrop
                        onChange={(file: any) => {
                          updateUploads(index, file);
                        }}
                      >
                        <div className="ion-padding text-center">
                          <IonNote>{/* Select File */}</IonNote>

                          <br />
                          <IonButton
                            disabled={loading}
                            fill={`outline`}
                            color={`dark`}
                          >
                            <IonIcon
                              icon={arrowUpOutline}
                              slot={`start`}
                            ></IonIcon>
                            <IonLabel>Select PDF File</IonLabel>
                          </IonButton>
                        </div>
                      </DragDrop>
                      {/* <div className="ion-padding text-center">
                                                <img src={local_images.PDFicon} alt="" />
                                                <br />
                                                <IonToolbar>
                                                    <IonItem lines={`none`}>
                                                        <IonNote slot={`end`}>24%</IonNote>
                                                    </IonItem>
                                                    <IonProgressBar color={`success`} type={`indeterminate`}></IonProgressBar>
                                                </IonToolbar>
                                            </div> */}
                    </div>
                  );
                })}
              </IonCol>

              <IonCol></IonCol>
            </IonRow>
          </IonGrid>
        </IonToolbar>
        <IonToolbar style={{ textAlign: `center` }}>
          <IonButton onClick={uploadPapers} disabled={!ready} color={`dark`}>
            <IonLabel> CONFIRM UPLOADS</IonLabel>
          </IonButton>
        </IonToolbar>
        <br />
        <br />
        <br />
      </IonContent>
      <IonLabel color={`warning`}>
        <div className="ion-padding web">
          {" "}
          <small>
            <b>Note:</b> Submission of wrong or spam content is not accepted. Your account will be blocked.
          </small>
        </div>
      </IonLabel>
    </IonPage>
  );
}

export default UploadTaskPage;

export function DragDrop(props: {
  children: any;
  onChange: (file: File | Blob) => void;
}) {
  const [file, setFile] = useState(null);
  const [error, seterror] = useState("");
  const fileTypes = ["pdf", "docx", "odt"];

  const handleChange = (file: any) => {
    seterror("");
    const matches = fileTypes.filter((res) => {
      return !!file.type.toLowerCase().match(res);
    });
    if (matches.length <= 0) {
      seterror("Unsupported File format");
      return;
    }
    setFile(file);
    props.onChange(file);
  };
  return (
    <FileUploader
      onTypeError={(err: any) => {
        seterror(err);
        alert(err);
      }}
      maxSize={20}
      handleChange={(val: any) => handleChange(val)}
      name="file"
      types={fileTypes}
    >
      {props.children}
      {
        <div className="ion-padding-bottom">
          <small>
            {" "}
            <IonLabel color="danger">{error}</IonLabel>
          </small>
        </div>
      }
    </FileUploader>
  );
}

function getFinalPaper(paper: UserDocument, user: Person): UserDocument {
  return {
    cost: paper.cost || "",
    create_timestamp: Date.now(),
    department: paper.department || "",
    faculty: paper.department || "",
    files: paper.files || [""],
    status: `pending`,
    times_submitted: Date.now(),
    title: paper.title || "",
    update_timestamp: Date.now(),
    user_email: user.email || "",
    user_name: user.name || "",
    user_tel: user.tel || "",
    user_photoURL: user.photoURL || "",
    message: ``,
    id: paper.id || "",
    code: paper.code || "",
    type: paper.type || "",
    year: paper.year || "",
  };
}
