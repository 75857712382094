import { fstore } from ".";
import { Person } from "../components/types";



export function getSubmitted(email: string, onSnapshot: (docs: any[]) => void) {
    fstore.collection(`people/${email}/submitted`).onSnapshot(res => {
        onSnapshot(res.docs.map(doc => doc.data()));
    })
}

export function getNotifications(person: Person, generalInfo: (docs: any[]) => void, specificInfo: (docs: any[]) => void) {
    fstore.collection(`people/${person.email}/notifications`).orderBy(`timestamp`, `desc`).onSnapshot(res => {
        specificInfo(res.docs.map(doc => doc.data()));
    })
    fstore.collection(`faculties/${person.faculty}/notifications`).orderBy(`timestamp`, `desc`).onSnapshot(res => {
        generalInfo(res.docs.map(doc => doc.data()));
    })
}