import {
  IonAvatar,
  IonBackButton,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardSubtitle,
  IonCardTitle,
  IonCheckbox,
  IonChip,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonImg,
  IonItem,
  IonLabel,
  IonMenuButton,
  IonNote,
  IonPage,
  IonProgressBar,
  IonRippleEffect,
  IonRow,
  IonSearchbar,
  IonSelect,
  IonSelectOption,
  IonTitle,
  IonToolbar,
  useIonViewDidEnter,
} from "@ionic/react";
import { reverse } from "dns";
import {
  chevronForward,
  closeCircle,
  closeCircleOutline,
  closeOutline,
  filter,
  filterSharp,
} from "ionicons/icons";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Person, QDocument } from "../../../components/types";
import { fstore } from "../../../Firebase";
import { tryCatch } from "../../../Firebase/admin";
import { selectUser } from "../../../State/userstate";
import "../../../style/responsive.css";
const search_image = `http://media.tumblr.com/d1882c631fe517b4bb0bd3868022cd53/tumblr_inline_mk89bd0ND91qz4rgp.jpg`;

function UploadPaperSolution() {
  const user: Person = useSelector(selectUser);
  const [phrase, setphrase] = useState(``);
  const [searching, setsearching] = useState(false);
  const [papers, setpapers] = useState<QDocument[]>([]);
  const [visible_papers, setvisible_papers] = useState<QDocument[]>([]);
  const [all_papers, setall_papers] = useState<QDocument[]>([]);
  const [selected, setselected] = useState<QDocument[]>([]);
  const history = useHistory();
  const [semester, setsemester] = useState<1 | 2>(2);
  const pivot = 40;
  const [pagerIndex, setpagerIndex] = useState(pivot);
  const [pagerList, setpagerList] = useState<string[]>([]);
  const [current_faculty, setcurrent_faculty] = useState("All");
  const [current_exam_type, setcurrent_exam_type] = useState("All");
  const [mobileFilter, setmobileFilter] = useState(false);
  const [viewEntered, setviewEntered] = useState(false);

  const semester_ref = useRef<HTMLIonSelectElement>(null);
  const exam_type_ref = useRef<HTMLIonSelectElement>(null);
  const department_ref = useRef<HTMLIonSelectElement>(null);

  useIonViewDidEnter(() => {
    setviewEntered(true);
    setTimeout(() => {
      setviewEntered(false);
    }, 500);
  });
  function getPapers() {
    tryCatch(async () => {
      setsearching(true);

      fstore
        .collection(`faculties`)
        .doc(`FET`)
        .collection(`approved`)
        .onSnapshot((documents) => {
          const docs: any[] = documents.docs.map((paper) => paper.data());
          setpapers([
            ...docs.sort((a, b) => {
              return a.title < b.title ? -1 : 1;
            }),
          ]);
          setall_papers([
            ...docs.sort((a, b) => {
              return a.title < b.title ? -1 : 1;
            }),
          ]);
          setvisible_papers([
            ...docs.filter((pap, index) => index <= pagerIndex),
          ]);
        //   console.log(all_papers);
          setTimeout(() => {
            setsearching(false);
          }, 2000);
        });
    });
  }

  function filterPapers(sem: 1 | 2, faculty: string, exam_type: string) {
    setsearching(true);
    let paps = filterSemester(all_papers, sem);
    paps = filterByFaculty(paps, faculty);
    let temp_type: "ca" | "exam" | "resit" | "all";
    if (exam_type !== "ca" && exam_type !== "exam" && exam_type !== "resit") {
      temp_type = "all";
    } else {
      temp_type = exam_type;
    }
    paps = filterByExamType(paps, temp_type);

    setTimeout(() => {
      setpapers([...paps]);
      setvisible_papers([...paps.filter((pap, index) => index <= pivot)]);
      setpagerIndex(pivot);
      //   setsemester(sem);
      setsearching(false);
    }, 500);
  }

  function filterSemester(original_papers: QDocument[], sem: 1 | 2) {
    const temp_papers = original_papers;
    let paps = temp_papers.filter((paper) => {
      let num = "";
      for (let i = 0; i < paper.code.length; i++) {
        num +=
          !!parseInt(paper.code[i]) || parseInt(paper.code[i]) == 0
            ? paper.code[i]
            : "";
      }
      let numberCode = +num.trim();
    //   console.log(paper.code, numberCode, numberCode % 2, sem, "evaluation");
      if (sem == 1 && numberCode % 2 == 1) {
        return true;
      } else if (sem == 2 && numberCode % 2 == 0) {
        return true;
      }
      return false;
    });
    return paps;
  }

  function filterPhrase(phrase: string) {
    phrase = phrase.toLowerCase();
    const words = phrase.split(` `);

    if (!phrase) {
      setvisible_papers(papers);
      return;
    }

    let p = papers;
    p = p.filter((paper) => {
      if (
        reverseMatch(paper.code.toLowerCase(), phrase) ||
        reverseMatch(paper.title.toLowerCase(), phrase) ||
        reverseMatch(paper.type.toLowerCase(), phrase)
      ) {
        return true;
      }
      return false;
    });
    p.sort((a, b) => {
      let pa = reverseMatch(a.code.toLowerCase(), phrase) ? 1 : 0;
      pa += reverseMatch(a.title.toLowerCase(), phrase) ? 1 : 0;
      pa += reverseMatch(a.type.toLowerCase(), phrase) ? 1 : 0;
      pa += reverseMatch(a.year.toLowerCase(), phrase) ? 1 : 0;

      let pb = reverseMatch(b.code.toLowerCase(), phrase) ? 1 : 0;
      pb += reverseMatch(b.title.toLowerCase(), phrase) ? 1 : 0;
      pb += reverseMatch(b.type.toLowerCase(), phrase) ? 1 : 0;
      pb += reverseMatch(b.year.toLowerCase(), phrase) ? 1 : 0;

      return -pa + pb;
    });

    setvisible_papers([...p]);
  }

  useEffect(() => {
    getPapers();
  }, []);

  useEffect(() => {
    updatePagerList();
  }, [papers]);

  function uploadPaper() {
    history.push(`/create/solutions/upload`, selected);
  }

  useIonViewDidEnter(() => {
    setselected([]);
    setpagerIndex(pivot);
    getPapers();
  });

  function filterByFaculty(main_paper_source: QDocument[], faculty: string) {
    const temp_papers = main_paper_source;
    if (faculty.toLowerCase() == "all") {
      return main_paper_source;
    }
    let paps = temp_papers.filter((paper) => {
      if (paper.code.toLowerCase().match(faculty.toLowerCase())) {
        return true;
      }
      return false;
    });
    return paps;
  }

  function filterByExamType(
    main_paper_source: QDocument[],
    exam_type: "ca" | "exam" | "resit" | "catch-up" | "all"
  ) {
    const temp_papers = main_paper_source;
    let paps = temp_papers.filter((paper) => {
      if (paper.type == exam_type || exam_type == "all") {
        return true;
      }
      return false;
    });
    return paps;
  }
  function verifyActive(num: number): boolean {
    if (parseInt((pagerIndex / pivot).toString()) == num + 1) {
      return true;
    }
    return false;
  }

  function updatePagerList() {
    let len = papers.length;
    let temp_arr: string[] = [""];
    while (len - pivot > 0) {
      temp_arr = [...temp_arr, ""];
      len -= pivot;
    }
    // console.log(temp_arr, "temp_arr");
    setpagerList([...temp_arr]);
  }

  function displacePivot(pager: number) {
    const newPager = (pager + 1) * pivot;
    let paps = papers.filter(
      (pap, index) => index < newPager && index >= pagerIndex
    );
    if (paps.length <= 0) {
      paps = papers.filter(
        (pap, index) => index < newPager && index >= newPager - pivot
      );
    }
    setvisible_papers([...paps]);
    setpagerIndex(newPager);
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color={`dark`}>
          <IonButtons slot="start">
            <IonBackButton defaultHref={"/home"}></IonBackButton>
          </IonButtons>
          <IonButtons className="filter-web" slot="end">
            <IonSelect
              ref={semester_ref}
              onIonChange={(e) => {
                setsemester(e.detail.value || semester);
                filterPapers(
                  e.detail.value || 1,
                  current_faculty,
                  current_exam_type
                );
              }}
              value={semester}
            >
              <IonSelectOption value={1}>First Semester</IonSelectOption>
              <IonSelectOption value={2}>Second Semester</IonSelectOption>
            </IonSelect>
            <IonSelect
              ref={department_ref}
              value={current_faculty}
              onIonChange={(e) => {
                setcurrent_faculty(e.detail.value || "All");
                filterPapers(
                  semester,
                  e.detail.value || "All",
                  current_exam_type
                );
              }}
              style={{ marginRight: "20px" }}
            >
              <IonSelectOption value="CEF">CEF</IonSelectOption>
              <IonSelectOption value="EEF">EEF</IonSelectOption>
              <IonSelectOption value="CIVIL">CIVIL</IonSelectOption>
              <IonSelectOption value="All">All</IonSelectOption>
            </IonSelect>
            <IonSelect
              ref={exam_type_ref}
              value={current_exam_type}
              onIonChange={(e) => {
                setcurrent_exam_type(e.detail.value || "All");
                filterPapers(
                  semester,
                  current_faculty,
                  e.detail.value || "All"
                );
              }}
              style={{ marginRight: "20px" }}
            >
              <IonSelectOption value="ca">CA</IonSelectOption>
              <IonSelectOption value="exams">Exams</IonSelectOption>
              <IonSelectOption value="resit">Resit</IonSelectOption>
              <IonSelectOption>All</IonSelectOption>
            </IonSelect>
          </IonButtons>
          <IonButtons
            className="filter-mobile ion-padding-horizontal"
            slot="end"
          >
            <IonButton onClick={() => setmobileFilter(!mobileFilter)}>
              <IonLabel className="ion-margin-end">Filter </IonLabel>
              <IonIcon
                icon={!mobileFilter ? filterSharp : closeOutline}
              ></IonIcon>
            </IonButton>
          </IonButtons>
          <IonTitle>Upload New</IonTitle>
          <IonAvatar style={{ transform: `scale(0.8)` }} slot={`end`}>
            {user.photoURL ? (
              <IonImg src={user.photoURL}></IonImg>
            ) : (
              <IonMenuButton></IonMenuButton>
            )}
          </IonAvatar>
          {searching && (
            <IonProgressBar
              type={`indeterminate`}
              style={{ borderRadius: `20px` }}
              color={`success`}
            ></IonProgressBar>
          )}
        </IonToolbar>
        {mobileFilter && (
          <IonToolbar className="filter-mobile">
            <IonButton
              onClick={() => setmobileFilter(false)}
              color="dark"
              slot="start"
              fill="clear"
            >
              <IonIcon size="large" icon={closeCircleOutline}></IonIcon>
            </IonButton>
            <IonButtons slot="end">
              <IonChip onClick={() => semester_ref.current?.click()}>
                semester : {semester}
              </IonChip>
              <IonChip onClick={() => exam_type_ref.current?.click()}>
                type : {current_exam_type}{" "}
              </IonChip>
              <IonChip onClick={() => department_ref.current?.click()}>
                department: {current_faculty}
              </IonChip>
            </IonButtons>
          </IonToolbar>
        )}
      </IonHeader>
      <IonContent>
        <IonToolbar>
          <IonGrid>
            <IonRow>
              <IonCol></IonCol>
              <IonCol
                size={`12`}
                sizeSm={`11`}
                sizeMd={`8`}
                sizeLg={`6`}
                sizeXl={`5`}
              >
                {/* <IonSearchbar placeholder={`filter by title`} searchIcon={filter} onIonChange={e => { setphrase(e.detail.value || ``); filterPhrase(e.detail.value || ``) }} value={phrase} color={`light`}></IonSearchbar>
                                {searching && <IonProgressBar type={`indeterminate`} style={{ borderRadius: `20px` }} color={`success`}></IonProgressBar>}
                                <br />
                                <small> <i> search phrase <b>'{phrase}'</b> </i></small>

                                <br /> */}
                <IonCard
                  style={{ background: `#468929de`, color: `white` }}
                  className={`card ion-padding ion-margin`}
                >
                  Please select the courses below that you wish to provide
                  solutions for (take note of the year and type). Your solutions
                  should correct, legible and clean
                </IonCard>
                <br />
                <IonCardContent>
                  <i>
                    Below are FET Questions for FET Electrical, Computer and
                    Civil Engineering{" "}
                  </i>
                </IonCardContent>
              </IonCol>
              <IonCol></IonCol>
            </IonRow>
            <IonRow>
              <IonCol></IonCol>
              {!viewEntered && (
                <IonCol
                  sizeSm={`11`}
                  sizeMd={`9`}
                  sizeLg={`8`}
                  sizeXl={`7`}
                  size={`12`}
                >
                  {visible_papers.map((res, i) => {
                    if (!res.available) {
                      return ``;
                    }
                    return (
                      <div
                        key={i}
                        className={` ${
                          !res.available ? `ion-activatable ripple-parent` : ``
                        } card ion-margin-bottom container-fluid`}
                      >
                        <IonItem
                          className="ion-text-capitalize"
                          lines={`none`}
                          color={`none`}
                        >
                          <IonCardSubtitle>
                            {" "}
                            {res.code} {res.title} {res.type} {res.year}
                          </IonCardSubtitle>
                          {res.available && (
                            <IonCheckbox
                              onIonChange={(e) => {
                                if (e.detail.checked && res.available) {
                                  setselected([...selected, res]);
                                } else {
                                  const temp = selected;
                                  setselected([
                                    ...temp.filter((pap) => res.id != pap.id),
                                  ]);
                                }
                              }}
                              slot={`end`}
                            ></IonCheckbox>
                          )}
                          {!res.available && (
                            <IonNote slot={`end`} color={`medium`}>
                              {" "}
                              <i>taken</i>
                            </IonNote>
                          )}
                          <IonRippleEffect></IonRippleEffect>
                        </IonItem>
                      </div>
                    );
                  })}
                  <IonToolbar className="text-center">
                    <IonRow>
                      <IonCol></IonCol>
                      <IonCol size="11" sizeMd="4">
                        {" "}
                        <IonButtons>
                          {pagerList.map((item, index) => {
                            return (
                              <IonButton
                              key={index}
                                onClick={() => {
                                  displacePivot(index);
                                }}
                                fill={verifyActive(index) ? "solid" : "default"}
                                disabled={verifyActive(index)}
                                color={
                                  verifyActive(index) ? "success" : "light"
                                }
                              >
                                <IonLabel
                                  color={
                                    verifyActive(index) ? "dark" : "success"
                                  }
                                >
                                  {" "}
                                  {index + 1}
                                </IonLabel>
                              </IonButton>
                            );
                          })}
                        </IonButtons>
                      </IonCol>
                      <IonCol></IonCol>
                    </IonRow>
                  </IonToolbar>
                  <div className={`text-center`}>
                    {papers.length <= 0 && !searching && (
                      <div className={`card`}>
                        <IonCardContent>
                          <IonCardTitle>No papers available</IonCardTitle>
                        </IonCardContent>
                      </div>
                    )}
                  </div>

                  <br />
                  <br />
                  <br />
                  {}
                </IonCol>
              )}
              <IonCol></IonCol>
            </IonRow>
          </IonGrid>
        </IonToolbar>
      </IonContent>
      {selected.length > 0 && (
        <IonToolbar className="ion-padding-horizontal">
          <IonButtons slot={`end`}>
            <IonButton
              fill={`solid`}
              onClick={() => uploadPaper()}
              color={`success`}
            >
              <IonLabel>Next</IonLabel>
              <IonIcon slot={`end`} icon={chevronForward}></IonIcon>
            </IonButton>
          </IonButtons>
        </IonToolbar>
      )}
    </IonPage>
  );
}

export default UploadPaperSolution;

export function reverseMatch(phrase1: string, phrase2: string) {
  return phrase1.match(phrase2) || phrase2.match(phrase1);
}
