import {
  IonAlert,
  IonAvatar,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCheckbox,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonImg,
  IonItem,
  IonLabel,
  IonLoading,
  IonMenuButton,
  IonNote,
  IonPage,
  IonProgressBar,
  IonRippleEffect,
  IonRow,
  IonSearchbar,
  IonText,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { chevronForward } from "ionicons/icons";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import PaymentRequestModal from "../../../components/PaymentRequestModal";
import {
  Person,
  QPaymentRequest,
  UserDocument,
} from "../../../components/types";
import { fstore } from "../../../Firebase";
import { getUser } from "../../../State/local";
import { selectPapers } from "../../../State/papersState";
import { selectUser, updateUser } from "../../../State/userstate";
import { sample_image } from "./UploadTaskPage";

function Account() {
  const user: Person = useSelector(selectUser);
  const [documents, setdocuments] = useState<UserDocument[]>([]);
  const [balance, setbalance] = useState<number>(0);
  const [showAlert, setshowAlert] = useState(false);
  const [loading, setloading] = useState(false);
  const [confirmRequest, setconfirmRequest] = useState(false);
  const history = useHistory();
  const papers: UserDocument[] = useSelector(selectPapers);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!user?.email) {
      getUserInfo();
    }
    measureBalance();
  }, []);

  // useEffect(() => {
  //     let sum = 0;
  //     documents.filter(doc => doc.status == `approved`).map(res => {
  //         sum = balance + +res.cost
  //     });
  //     setbalance(sum)

  // }, [document])

  async function getUserInfo() {
    const user = await getUser();
    alert(``);
    if (user && user.email) {
      dispatch(updateUser(user));
    } else {
      history.push(`/login`);
    }
  }

  function measureBalance() {
    let sum = 0;
    papers?.map((pap) => {
      if (pap.status == `approved`) {
        sum += 50;
      }
    });
    setbalance(sum);
  }

  async function requestPayment() {
    if (balance < 150) {
      setshowAlert(true);
    } else {
      setconfirmRequest(true);
    }
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color={`dark`}>
          <IonMenuButton slot={`end`}></IonMenuButton>
          <IonTitle>Account Details</IonTitle>
        </IonToolbar>
      </IonHeader>
      {loading && <IonProgressBar type={`indeterminate`}></IonProgressBar>}
      <IonContent>
        <br />
        <br />
        <IonAlert
          message={`You can only request payments if your balance is 150 FCFA and above.\n${
            papers.length > 0
              ? `Please wait for your papers to be approved`
              : ``
          }`}
          onDidDismiss={() => {
            setshowAlert(false);
          }}
          header={`Payment request failed`}
          isOpen={showAlert}
        ></IonAlert>
        <IonLoading
          message={`hold on please`}
          isOpen={loading}
          onDidDismiss={() => setloading(false)}
        ></IonLoading>
        <IonToolbar>
          <IonGrid>
            <IonRow>
              <IonCol></IonCol>
              <IonCol size={`12`} sizeSm={`11`} sizeMd={`8`} sizeLg={`6`}>
                <IonAvatar style={{ margin: `auto`, transform: `scale(1.3)` }}>
                  <IonImg src={user.photoURL}></IonImg>
                </IonAvatar>
              </IonCol>
              <IonCol></IonCol>
            </IonRow>
            <IonRow>
              <IonCol></IonCol>
              <IonCol
                sizeSm={`11`}
                sizeMd={`9`}
                sizeLg={`8`}
                sizeXl={`7`}
                size={`12`}
              >
                <IonRow className={`text-center`}>
                  <IonCol>
                    <div>
                      <h1>{user.name}</h1>
                      <h5>
                        {user.faculty}, {user.school}
                      </h5>
                      <h5>{user.tel}</h5>
                    </div>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <IonCard mode={`ios`} className={`ion-padding`}>
                      <IonCardHeader>
                        <IonCardTitle color="medium">Balance</IonCardTitle>
                      </IonCardHeader>
                      <IonCardContent>
                        <IonCardSubtitle>
                          <IonText className="ion-float-end ion-padding-horizontal">
                            <h1> <span style={{fontSize:"2em"}}>{balance}</span> &nbsp;<span style={{fontSize:"0.7em"}}>FCFA</span></h1>
                          </IonText>{" "}
                          
                        </IonCardSubtitle>
                      </IonCardContent>
                    </IonCard>
                  </IonCol>
                  <IonCol>
                    <IonCard className={`ion-padding`} mode={`ios`}>
                      <IonCardHeader>
                        <IonCardTitle color="medium">
                          Uploaded <span className="ion-float-end">20</span>
                        </IonCardTitle>
                      </IonCardHeader>
                      <IonCardContent>
                        <IonCardSubtitle>
                          {papers.length} questions
                        </IonCardSubtitle>
                        <IonCardSubtitle>
                          {papers.length} answers
                        </IonCardSubtitle>
                      </IonCardContent>
                    </IonCard>
                  </IonCol>
                </IonRow>
              </IonCol>
              <IonCol></IonCol>
            </IonRow>{" "}
            <IonRow>
              <IonCol></IonCol>
              <IonCol
                size={`12`}
                sizeSm={`11`}
                sizeMd={`8`}
                sizeLg={`6`}
                sizeXl={`5`}
              >
                <IonToolbar className={`text-center`}>
                  <br />
                  <br />
                  <br />
                  <IonButton
                    onClick={() => {
                      requestPayment();
                    }}
                    color={`dark`}
                  >
                    Request Payment
                  </IonButton>
                </IonToolbar>
              </IonCol>
              <IonCol></IonCol>
            </IonRow>
          </IonGrid>
        </IonToolbar>
        <PaymentRequestModal
          onDidDismiss={() => setconfirmRequest(false)}
          isOpen={confirmRequest}
        ></PaymentRequestModal>
      </IonContent>
    </IonPage>
  );
}

export default Account;
