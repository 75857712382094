import { QNotification } from "../components/types";

interface Action {
    payload: QNotification[],
    type: `update_general` | `update_specific`
}

export interface NotifState {
    general: QNotification[],
    specific: QNotification[],
    all: QNotification[],
}

const initial_state: NotifState = {
    general: [],
    specific: [],
    all: []
}

function updateGeneral(payload: QNotification[]): Action {
    return (
        {
            type: `update_general`,
            payload
        }
    )
}
function updateSpeific(payload: QNotification[]): Action {
    return (
        {
            type: `update_specific`,
            payload
        }
    )
}
// function updateAll(payload: Person): Action {
//     return (
//         {
//             type: `update_all`,
//             payload
//         }
//     )
// }


function notificationReducer(state = initial_state, action: Action): NotifState {
    if (action.type == `update_general`) return { ...state, general: action.payload, all: [...action.payload, ...state.specific] };
    if (action.type == `update_specific`) return { ...state, specific: action.payload, all: [...state.general, ...action.payload,] };
    else
        return state
}

export const selectNotifications = (state: any) => state.notificationReducer

export { updateSpeific, updateGeneral };

export default notificationReducer



