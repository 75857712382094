import {
  IonApp, 
  setupIonicReact,
} from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router"; 
import Menu from "./pages/questions/user/Menu"; 
/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";

import React from "react";
import Routes from "./components/Routes";
import QuestionsAdminMenu from "./pages/questions/admin/components/Menu";
import"./App.css";
import ReviewAdminMenu from "./pages/answers/review/components/Menu";

setupIonicReact({
  mode: "md"
});

const App: React.FC = () => {
  return (
    <IonApp>
      <IonReactRouter> 
        <Menu/>
        {/* <QuestionsAdminMenu/> */}
        {/* <ReviewAdminMenu/> */}
        <Routes></Routes>
      </IonReactRouter>
    </IonApp>
  );
};

export default App;
