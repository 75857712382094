import { IonAlert, IonAvatar, IonButton, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCol, IonContent, IonGrid, IonHeader, IonImg, IonInput, IonItem, IonLabel, IonLoading, IonModal, IonPage, IonPopover, IonRow, IonSelect, IonSelectOption, IonTitle, IonToolbar, useIonViewWillEnter } from '@ionic/react'
import React, { useEffect, useState } from 'react'
import { app, auth, fstore } from '../../../../Firebase';
import "../../../../style/Auth.css";
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import firebase from 'firebase';
import { AdminPerson, Person } from '../../../../components/types';
import { getAdmin, getPerson, updateAdminRecord } from '../../../../Firebase/login';
import { useDispatch, useSelector } from 'react-redux';
import { updateUser } from '../../../../State/userstate';
import AddDetailModal from '../../../../components/AddDetailModal';
import { getLocalAdmin, savelocalAdmin, saveUser } from '../../../../State/local';
import { useHistory } from 'react-router'; 
import { selectAdmin, updateAdmin } from '../../../../State/adminState';
import { Storage } from '@capacitor/storage';
import { local_images } from '../../../../images/images';

const ReviewAdminLogin: React.FC = () => {
    const [addDetail, setaddDetail] = useState(false)
    const [loading, setloading] = useState(false)
    const [noPermission, setnoPermission] = useState(false)

    const dispatch = useDispatch();
    const history = useHistory();



    const admin: AdminPerson = useSelector(selectAdmin)
    async function initAdmin() {
        if (!admin.email) {
            const p = await getLocalAdmin()
            if (p) {
                dispatch(updateAdmin(p))
            }
            else {
                history.push(`/admin/login`)
            }
        }
    }

    useIonViewWillEnter(() => {
        initAdmin()
    })
    useEffect(() => {
        console.log(admin)
        initAdmin()
    }, [])




    const uiConfig = {
        // Popup signin flow rather than redirect flow.
        signInFlow: 'popup',
        // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
        signInSuccessUrl: '/signedIn',
        // We will display Google and Facebook as auth providers.
        callbacks: {
            // Avoid redirects after sign-in.
            signInSuccessWithAuthResult: (a1: { user: firebase.User, credential: any, operationType: string, }) => {
                console.log(a1.user.email)
                signInUser(a1.user)

                return false
            },
        },
        signInOptions: [
            firebase.auth.GoogleAuthProvider.PROVIDER_ID,
            // firebase.auth.FacebookAuthProvider.PROVIDER_ID,
        ],
    };
    

    async function signInUser(user: firebase.User) {
        if (user.email) {
            setloading(true)
            let admin: undefined | AdminPerson = await getAdmin(user.email);
            if (admin) {
                console.log(admin)
                savelocalAdmin(admin)

                if (!admin.photoURL ) {
                    const obj: AdminPerson = {
                        email: user.email,
                        name: user.displayName || ``,
                        photoURL: user.photoURL || ``,
                    }
                    admin={...admin, ...obj}
                    updateAdminRecord(admin,obj)
                }
                dispatch(updateAdmin(admin))
                history.push(`/review/home`)

            }
            else {
                // const person: Person = {
                //     email: user.email,
                //     name: user.displayName || ``,
                //     photoURL: user.photoURL || ``,
                // }
                // dispatch(updateAdmin(person))
                // saveAdmin(person)
                // history.push(`/admin/home`)

                setnoPermission(true)

            }
        }
        setloading(false)
    }
    return (
        <IonPage className={`auth`}>
            <IonContent>
                <IonAlert buttons={[{ text: `ok` }]} header={`Permission Denied`} message={`You do not have access to the admin Account`} isOpen={noPermission} onDidDismiss={() => setnoPermission(false)}></IonAlert>
                <IonToolbar className={`content-toolbar`} color={`dark`}>
                    <IonGrid>
                        <IonRow>
                            <IonCol>
                                <div style={{ height: `10vh` }}></div>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol></IonCol>
                            <IonCol size={`12`} sizeMd={`6`} sizeLg={`6`}>
                                <IonCard color={`dark`} mode={`ios`} className={`login-hero-card `} >
                                    <IonCardHeader>
                                        <IonImg className={`login-logo`} src={local_images.logo}></IonImg>
                                    </IonCardHeader>
                                    <IonCardContent mode={`md`} className={`login-content`}>
                                        <h1>QCP ADMIN</h1>
                                        <br />
                                        <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />
                                        <br />
                                        <br />
                                        <br />
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>
                            <IonCol></IonCol>
                        </IonRow>
                    </IonGrid>
                </IonToolbar>
                <IonLoading spinner={`lines-sharp`} onDidDismiss={() => setloading(false)} isOpen={loading} message={`hold on Quesereth`}></IonLoading>
            </IonContent>

        </IonPage>

    );
};

export default ReviewAdminLogin;
