import './ExploreContainer.css';
 
interface ContainerProps {
  name: string;
}

const ExploreContainer: React.FC<ContainerProps> = ({ name }) => {
  return (
    <div className="container-io container">
      <strong>{name}</strong>
      <p>Your {name} Will Appear Here.</p>
    </div>
  );
};

export default ExploreContainer;
