import {
  IonAvatar,
  IonBadge,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonIcon,
  IonImg,
  IonItem,
  IonItemDivider,
  IonLabel,
  IonProgressBar,
  IonRadio,
  IonRadioGroup,
  IonTextarea,
  IonToolbar,
} from "@ionic/react";
import { close } from "ionicons/icons";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { QNotification, UserDocument } from "../../../../components/types";
import { fstore } from "../../../../Firebase";
import shape from "../images/p1.jpg";
import axios from "axios";

const PendingCard: React.FC<{ paper: UserDocument }> = ({ paper }) => {
  const [respond, setrespond] = useState(false)
  const [selected, setselected] = useState()
  const [message, setmessage] = useState(``)
  const [loading, setloading] = useState(false)
  const history = useHistory()

  function openFiles() {
    const a = document.createElement(`a`)
    a.target = `__blank`
    paper.files.map(file => {
      a.href = file;
      a.click()
    })
  }

  async function submit(e: any) {
    e.preventDefault()
    setloading(true)
    let notificationObj: QNotification = {
      description: ``,
      link: ``,
      reciever: paper.user_email,
      sender: `admin`,
      timestamp: Date.now(),
      title: ``
    }
    if (selected && selected == `accept`) {
      fstore.collection(`people`)
        .doc(paper.user_email)
        .collection(`submitted`)
        .doc(paper.id)
        .update({ status: `approved`, message })

      fstore.collection(`faculties`)
        .doc(`FET`)
        .collection(`pending`)
        .doc(paper.id)
        .delete();

      await fstore.collection(`faculties`)
        .doc(`FET`)
        .collection(`approved`)
        .doc(paper.id)
        .set({ ...paper, message, status: `approved` })

      notificationObj = {
        ...notificationObj,
        description: message,
        link: ``,
        title: `Paper Accepted`
      }

    }
    else if (selected && selected == `reject`) {

      fstore.collection(`people`)
        .doc(paper.user_email)
        .collection(`submitted`)
        .doc(paper.id)
        .update({ status: `rejected`, message })

      fstore.collection(`faculties`)
        .doc(`FET`)
        .collection(`pending`)
        .doc(paper.id)
        .delete();

      await fstore.collection(`faculties`)
        .doc(`FET`)
        .collection(`rejected`)
        .doc(paper.id)
        .set({ ...paper, message, status: `rejected`, })

        fstore.collection(`faculties`)
                    .doc(`FET`)
                    .collection(`papers`)
                    .doc(paper.id)
                    .update({ available: true });
            

      notificationObj = {
        ...notificationObj,
        description: message,
        link: ``,
        title: `Paper Rejected`
      }
    }
    else {
      alert(`niether rejected nor accepted`)
      setloading(false)
      return;
    }

    await fstore.collection(`people/${paper.user_email}/notifications`).add(notificationObj)

    //send email
    axios.post('https://quesers.herokuapp.com/email/sendCustom', {
      subject: `${paper.title} has been ${selected}ed`,
      html: uploaderEmailTemplate(paper, message), to: paper.user_email
    }).catch(console.log);

    setloading(false)
    setrespond(false)
    history.push(`/admin/home`)

  }
  return (
    <IonCard className={`card`} mode={`ios`}>
      {loading && <IonProgressBar type={`indeterminate`} ></IonProgressBar>}
      <IonCardContent mode={`md`}>
        <IonCardTitle>{paper.code} {paper.title} {paper.type} {paper.year}</IonCardTitle>
        <IonToolbar className="pt-3">
          <div slot="start">
            <IonCardSubtitle className="pt-0 h4">{paper.user_name}</IonCardSubtitle>
            <IonCardSubtitle className="pt-1">
              {paper.department}
            </IonCardSubtitle>
          </div>
          <IonAvatar slot="end">
            <IonImg src={paper.user_photoURL}></IonImg>
          </IonAvatar>
        </IonToolbar>
        <IonToolbar className="mt-3">
          <IonButtons slot={`end`}>
            <IonButton onClick={openFiles} fill={`outline`} color="dark">Open <IonBadge className={`ion-margin-start`} color={`dark`}> {paper?.files.filter(file=>file!=``).length}</IonBadge></IonButton>
            {!respond && <IonButton onClick={() => setrespond(true)} fill={`solid`} color="primary">Respond</IonButton>}
          </IonButtons>
        </IonToolbar>
        {respond && <IonItemDivider color={`light`}>
          <IonLabel>Respond</IonLabel>
          <IonButton onClick={() => setrespond(false)} fill={`clear`} slot={`end`}>
            <IonIcon icon={close}></IonIcon>
          </IonButton>
        </IonItemDivider>}
        {respond &&
          <IonToolbar >
            <div style={{ background: `#eaeaea`, padding: `10px` }}>
              <form onSubmit={submit} >
                <IonItem disabled={loading} color={`none`}>
                  <IonTextarea onIonChange={(e) => setmessage(e.detail.value || ``)} value={message} required placeholder={`please enter response comment`}></IonTextarea>
                </IonItem>
                <IonToolbar color={`none`}>
                  <IonRadioGroup onIonChange={(e) => setselected(e.detail.value)}>
                    <IonItem disabled={loading} lines={`none`} color={`none`}>
                      <IonRadio value={`accept`} slot={`start`}></IonRadio>
                      <label>Accept</label>
                    </IonItem>
                    <IonItem disabled={loading} lines={`none`} color={`none`}>
                      <IonRadio value={`reject`} slot={`start`}></IonRadio>
                      <label>Reject</label>
                    </IonItem>
                  </IonRadioGroup>
                </IonToolbar>
                <IonToolbar color={`none`} className="mt-3">
                  <IonButtons slot={`end`}>
                    <IonButton disabled={loading} type={`submit`} fill={`solid`} color="primary">Respond</IonButton>
                  </IonButtons>
                </IonToolbar>
              </form>
            </div>
          </IonToolbar>}
      </IonCardContent>
    </IonCard >
  );
};

export default PendingCard;



export function uploaderEmailTemplate(paper: UserDocument, message: string) {

  return (
    `
    <div>
        <style>
            .body{
              color:#3c3e3c
            }
            p{
              padding:20px;
              border: 1px solid #e0e3e0;
              margin:10px;
            }
        
        </style>
        <div className="body">
        <h5>
            Your paper ${paper.code} ${paper.title} ${paper.type} ${paper.year} has been reviewed and this is the message from the review team
        </h5>
        <p >
        ${message}

        <br>
        for more information contact us at quesersteam@gmail.com
          </p>
   
        </div>
    
    
    </div>
      
    `
  )
}