import {
  IonAlert,
  IonAvatar,
  IonBadge,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonContent,
  IonHeader,
  IonIcon,
  IonImg,
  IonItem,
  IonLabel,
  IonLoading,
  IonMenuButton,
  IonPage,
  IonSpinner,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import {
  chevronForward,
  chevronForwardOutline,
  remove,
  removeCircle,
  trash,
} from "ionicons/icons";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import {
  AdminPerson,
  confirmID,
  Person,
  QDocument,
  Uploader,
  UserDocument,
} from "../../../../components/types";
import { fstore } from "../../../../Firebase";
import { selectAdmin } from "../../../../State/adminState";

const Uploaders: React.FC = () => {
  const location = useLocation();
  const [assigned, setassigned] = useState<UserDocument[]>([]);
  const [ppPerson, setppPerson] = useState<number[]>([]);
  const [uploader, setUploader] = useState<Person[]>([]);
  const [confirm, setconfirm] = useState(false);
  const [loading, setloading] = useState(false);
  const [subloading, setsubloading] = useState(false);
  const [confirmID, setConfirmID] = useState<confirmID>();
  const admin: AdminPerson = useSelector(selectAdmin);

  async function unAssignPaper() {
    setloading(true);
    let temp = assigned.filter((p) => p.id === confirmID?.id);
    let paper = temp[0];
    paper.assigned = false;

    // set paper in unassigned collection
    await fstore
      .collection(`faculties`)
      .doc(`FET`)
      .collection(`unassigned`)
      .doc(paper.id)
      .set(paper);

    // remove paper from assigned collection
    await fstore
      .collection(`faculties`)
      .doc(`FET`)
      .collection(`assigned`)
      .doc(paper.id)
      .delete();

    // remove paper from uploader collection
    await fstore
      .collection("uploader")
      .doc(confirmID?.name)
      .collection("papers")
      .doc(paper.id)
      .delete();

    setloading(false);
  } 

  useEffect(() => {
    if (location.state) {
      const temp: any = location.state;
      console.log(temp);
      setassigned(temp.assigned);
      setUploader(temp.uploader);
      setppPerson(temp.lenghts);
      
    }
  }, [location]);
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color={`dark`} className="">
          <IonButtons slot="start">
            <IonMenuButton menu="admin"></IonMenuButton>
          </IonButtons>
          <IonTitle slot="start">Uploaders</IonTitle>
          <IonAvatar slot={"end"} className="p-2">
            {admin.photoURL && <IonImg src={admin.photoURL}></IonImg>}
          </IonAvatar>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className="container">
          <div className="container-fluid">
            {uploader.map((individual, key) => {
              return (
                <IonItem key={key} lines="inset" button>
                  <IonAvatar slot="start">
                    <IonImg src={individual.photoURL}></IonImg>
                  </IonAvatar>
                  <IonCardHeader slot="">
                    <IonCardTitle>{individual.name}</IonCardTitle>
                    <IonCardSubtitle>{individual.email}</IonCardSubtitle>
                    <IonCardSubtitle>
                      {individual.faculty} ~ {individual.department}
                    </IonCardSubtitle>
                    {subloading ? (
                      <IonCardSubtitle>
                        <IonBadge color="clear">Papers Assigned:</IonBadge>{" "}
                        <IonSpinner color="success" name="dots"></IonSpinner>
                      </IonCardSubtitle>
                    ) : (
                      <IonCardSubtitle>
                        <IonBadge color="clear">Papers Assigned:</IonBadge>{" "}
                        <IonBadge color="secondary">
                          {ppPerson[key]}
                        </IonBadge>
                      </IonCardSubtitle>
                    )}
                  </IonCardHeader>
                  <IonButtons slot="end">
                    <IonButton
                      color="success"
                      onClick={() => {
                        alert("clicked");
                      }}
                    >
                      <IonIcon icon={chevronForward} slot="icon-only"></IonIcon>
                    </IonButton>
                  </IonButtons>
                </IonItem>
              );
            })}
          </div>
          <div>
            {location.state ? (
              " "
            ) : (
              <IonCard mode="ios">
                <IonCardContent className="ion-text-center">
                  <IonCardTitle>No Pending Reviews</IonCardTitle>
                </IonCardContent>
              </IonCard>
            )}
          </div>
        </div>{" "}
        <IonLoading
          isOpen={loading}
          onDidDismiss={() => setloading(false)}
        ></IonLoading>
        <IonAlert
          isOpen={confirm}
          onDidDismiss={() => setconfirm(false)}
          cssClass="my-custom-class"
          header={"Confirm"}
          message={`Unassign <strong>${confirmID?.Course}</strong> from <strong>${confirmID?.name}</strong>?`}
          buttons={[
            {
              text: "Cancel",
              role: "cancel",
              cssClass: "secondary",
              id: "cancel-button",
              handler: (blah) => {
                console.log("Confirm Cancel: blah");
              },
            },
            {
              text: "Okay",
              id: "confirm-button",
              handler: () => {
                console.log("Confirm Okay");
                unAssignPaper();
              },
            },
          ]}
        />
      </IonContent>
    </IonPage>
  );
};

export default Uploaders;

interface PaperPerPerson { 
  email: string;
}
