import {
  IonAvatar,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardTitle,
  IonContent,
  IonHeader,
  IonImg,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { AdminPerson, UserDocument } from "../../../../components/types";
import { selectAdmin } from "../../../../State/adminState";
import PendingCard from "../components/PendingCard";
import shape from "../images/p2.jpg";

const Pending: React.FC = () => {
  const location = useLocation();
  const [pending, setpending] = useState<UserDocument[]>([]);
  const admin: AdminPerson = useSelector(selectAdmin);

  useEffect(() => {
    if (location.state) {
      const temp: any = location.state;
      setpending(temp);
    }
  }, [location]);
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="dark">
          <IonButtons slot="start">
            <IonMenuButton menu="admin"></IonMenuButton>
          </IonButtons>
          <IonTitle slot="start">Pending Reviews</IonTitle>
          <IonAvatar slot={"end"} className="p-2">
            {admin.photoURL && <IonImg src={admin.photoURL}></IonImg>}
          </IonAvatar>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className="container">
          <div className="">
            {pending.map((paper) => {
              return (
                <div className="row">
                  <div className="col"></div>
                  <div className="col-12 col-md-10 col-lg-8">
                    <PendingCard paper={paper}></PendingCard>
                  </div>
                  <div className="col"></div>
                </div>
              );
            })}
          </div>
          <div>
            {!location.state ? (
              " "
            ) : (
              <IonCard mode="ios">
                <IonCardContent className="ion-text-center">
                  <IonCardTitle>No Pending Reviews</IonCardTitle>
                </IonCardContent>
              </IonCard>
            )}
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Pending;
