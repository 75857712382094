import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonImg,
  IonItem,
  IonLabel,
  IonPage,
  IonRow,
  IonText,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import {
  callOutline,
  logoFacebook,
  logoInstagram,
  logoTwitter,
  logoWhatsapp,
  mailOutline,
} from "ionicons/icons";
import React from "react";
import { Link } from "react-router-dom";
import { local_images } from "../images/images";
import "../style/Landing.css";
import { logo } from "./questions/user/Login";

function LandingPage() {
  return (
    <IonPage>
      <IonHeader>
        <IonItem>
          <IonImg style={{ height: `40px` }} src={logo}></IonImg>
          <IonTitle>Community Platform</IonTitle>
          <IonButtons slot={`end`}>
            <IonGrid>
              <IonRow>
                <IonCol className={`header-link`}>
                  <IonButton href={`#about`}>About</IonButton>
                </IonCol>
                <IonCol className={`header-link`}>
                  <IonButton href={`#quesers`}>Quesers App</IonButton>
                </IonCol>
                <IonCol className={`header-link`}>
                  <IonButton href={`#team`}>Team</IonButton>
                </IonCol>
                <IonCol className={`header-link`}>
                  <IonButton href={`#contact`}> Contact</IonButton>
                </IonCol>
                <IonCol>
                  <IonButton routerLink={`/login`} fill={`solid`}>
                    SIGN IN
                  </IonButton>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonButtons>
        </IonItem>
      </IonHeader>
      <IonContent>
        <div className="card">
          <img
            style={{
              maxHeight: `80vh`,
              objectFit: `cover`,
              objectPosition: `90% 90%`,
            }}
            className="card-img"
            src={local_images.banner}
            alt=""
          />
          <div className="card-img-overlay banner-overlay">
            <div className="row">
              <div className="col-12 col-md-6 container-io container text-white text-center">
                <h2 className="w-80"> 
                  Make Money by Uploading a Clean Copy of your Old Past
                  Questions
                </h2> 
                <br />
                <IonToolbar color={`none`}>
                  <IonButton routerLink={`/home`} fill={`outline`}  color={`warning`}>
                    Upload Questions
                  </IonButton> 
                </IonToolbar>
              </div>
            </div>
          </div>
        </div> 
        <IonGrid>
          <IonRow>
            <IonCol size={`12`} sizeMd={`2`} sizeLg={`3`} sizeXl={`3`}>
              <section className="youtube-vid">
                {/* <iframe   height="315" src="https://www.youtube.com/embed/qqs-Eh708-4" title="YouTube video player" style={{border:`none`,width:`100%` }} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> */}
              </section>
            </IonCol>
            <IonCol size={`12`} sizeMd={`8`} sizeLg={`6`} sizeXl={`6`}>
              <section id={`about`} className="ion-padding">
                <h2>What is the Quesers Community Platform</h2>
                <br />
                <p>
                  The Quesers Community Platform is a software service that
                  enables you to upload your old past questions in a presentable
                  way and earn income when it passes the review stage.
                </p>
                <p>
                  In this way students can be able to earn from these old
                  Questions they no longer have use for and at the same time
                  helping junior students behind them who will reuse them while
                  studying for their exams.
                </p>
              </section>
              <section className="ion-padding">
                <h2>How to get paid on QCP</h2>
                <br />
                <p>
                  In order to make money using the QCP PLATFORM, it is very
                  straight forward.
                  <div className="container-fluid">
                    <ol>
                      <li> Signup into QCP </li>
                      <li>click on the add icon to create an upload</li>
                      <li>
                        Select from the List provided the paper you will love to
                        upload
                      </li>
                      <li>
                        upload your selected papers (make sure they are clean
                        and presentable)
                      </li>
                      <li>wait for your papers to be reviewed</li>
                      <li>Request payment</li>
                      <li>Enjoy</li>
                    </ol>
                  </div>
                </p>
              </section>
              <section className="ion-padding">
                <h2 id={`quesers`}>What is the Quesers App</h2>
                <br />
                <p>
                  Quesers is a search engine for past questions and answers.
                  Quesers is the new Google for past Questions and solutions.
                </p>
                <p>
                  Quesers provides past questions and solutions for students and
                  an in app reader that enables them to read the content they
                  have downloaded.
                </p>
                <p>
                  Students can now get access to verified solutions that will
                  help improve their academic performance.
                </p>
                Quesers has come to change the way education and technology
                blend in africa and in the world. creating a technology that can
                impact billions of students no matter, where they are from or
                who they are.
                <p>Quesers - Questions with Answers</p>
                <div className="container-fluid">
                  <IonButton
                    target={`__blank`}
                    href={`https://play.google.com/store/apps/details?id=io.ionic.com.app.com.app.quesers`}
                    fill={`outline`}
                    color={`success`}
                  >
                    GET QUESERS
                  </IonButton>
                </div>
              </section>
              <section className="ion-padding">
                <h2>Propose solutions and Get Paid</h2>
                <br />
                <p>
                  Our Goal is to help students improve their grades by providing
                  them with authentic study material while at the same time
                  providing a sustainable source of income for contributors with
                  a minimal amount of effort. We have highly paid contributors
                  who provide solutions for students. These contributors are
                  young adults who are intelligent enough to Authentic solutions
                  for users of the Quesers Application
                </p>
                <p>
                  In order to become a contributor you must be smart,
                  responsible and willing to serve the community.
                  <div style={{ height: `20px` }}></div>
                  <IonButton
                    color={`dark`}
                    target={`__blank`}
                    href={`https://www.quesers.org/contributors/main`}
                  >
                    Learn More{" "}
                  </IonButton>
                </p>
              </section>
              </IonCol>
            <IonCol size={`12`} sizeMd={`2`} sizeLg={`3`} sizeXl={`3`}></IonCol>
          </IonRow>
          <IonRow>
            <IonCol></IonCol>
            <IonCol size={`12`} sizeMd={`10`} sizeLg={`8`} sizeXl={`8`}>
              <section id={`team`} className="team">
                <h2>Our Team</h2>
                <br />
                <IonRow style={{ padding: `0px`, margin: 0 }}>
                  {team.map((res,index) => {
                    return (
                      <IonCol key={index}
                        style={{ padding: `0px`, margin: 0 }}
                        size={`12`}
                        sizeMd={`6`}
                        sizeLg={`4`}
                        sizeXl={`4`}
                      >
                        <IonCard style={{"height":"max-content"}} mode={`ios`} >
                          <IonImg className={`team-img`} src={res.url}></IonImg>
                          <IonCardContent>
                            <IonCardContent mode={`md`}>
                              <IonCardTitle>{res.name}</IonCardTitle>
                              <IonCardSubtitle>{res.role}</IonCardSubtitle>
                              <IonButtons>
                                <IonButton>
                                  <IonIcon icon={logoInstagram}></IonIcon>
                                </IonButton>
                                <IonButton>
                                  <IonIcon icon={logoFacebook}></IonIcon>
                                </IonButton>
                                <IonButton>
                                  <IonIcon icon={logoTwitter}></IonIcon>
                                </IonButton>
                              </IonButtons>
                            </IonCardContent>
                          </IonCardContent>
                        </IonCard>
                      </IonCol>
                    );
                  })}
                </IonRow>
              </section>
            </IonCol>
            <IonCol></IonCol>
          </IonRow>
          <IonRow>
            <IonCol></IonCol>
            <IonCol size={`12`} sizeMd={`8`} sizeLg={`6`}>
              <IonToolbar className={`ion-padding`} color={`light`}>
                <section id={`contact`} className="contact">
                  <h2>Contact US</h2>
                  <div className="ion-padding">
                    <IonToolbar color={`none`}>
                      <IonItem  target={`__blank`} href={`mailto:quesersteam@gmail.com`} button color={`none`} lines={`none`}>
                        <IonIcon slot={`start`} icon={mailOutline}></IonIcon>
                        <IonLabel>Mail Us</IonLabel>
                      </IonItem>
                    </IonToolbar>
                    <IonToolbar color={`none`}>
                      <IonItem href={`https://chat.whatsapp.com/LchBn6cw40YI7VL6G8Om2r`} target={`__blank`} button color={`none`} lines={`none`}>
                        <IonIcon slot={`start`} icon={callOutline}></IonIcon>
                        <IonLabel>Call Us</IonLabel>
                      </IonItem>
                    </IonToolbar>
                    <IonToolbar color={`none`}>
                      <IonItem button color={`none`} lines={`none`}>
                        <IonIcon slot={`start`} icon={logoWhatsapp}></IonIcon>
                        <IonLabel>Message Us</IonLabel>
                      </IonItem>
                    </IonToolbar>
                  </div>
                </section>
              </IonToolbar>
            </IonCol>
            <IonCol></IonCol>
          </IonRow>
        </IonGrid>
        <IonToolbar className={`ion-padding`} color={`dark`}>
          <IonLabel>
            {" "}
            A product of{" "}
            <a color={`success`} href="https://quesers.org">
              {" "}
              quesers.org
            </a>
          </IonLabel>
        </IonToolbar>
      </IonContent>
    </IonPage>
  );
}

export default LandingPage;

const team = [
  {
    name: `Oben Desmond`,
    role: `Chief Executive Officer (CEO)`,
    url: `https://www.quesers.org/assets/img/team/desmond.jpeg`,
  },
  {
    name: `Akumah Ndeh`,
    role: `Chief Technical Officer (CTO)`,
    url: `https://www.quesers.org/assets/img/team/ndeh.jpeg`,
  },
  {
    name: `Tambeayuk Agnes`,
    role: `Accountant`,
    url: `https://www.quesers.org/assets/img/team/agnes.jpeg`,
  },
  {
    name: `Tabi Lizzy`,
    role: `Marketing`,
    url: `https://www.quesers.org/assets/img/team/lizzy.jpeg`,
  },
  {
    name: `Theola Ashu`,
    role: `Head of Operations`,
    url: `https://www.quesers.org/assets/img/team/theola.jpeg`,
  },
  {
    name: `Kanla Honorine`,
    role: `Project Management`,
    url: local_images.Honorine,
  },
  {
    name: `Becky`,
    role: `Software Engineer`,
    url: local_images.Becky,
  },
  {
    name: `Nzo Daniel`,
    role: `Software Engineer`,
    url: local_images.Daniel,
  },
];
