import {
  IonAlert,
  IonAvatar,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonContent,
  IonHeader,
  IonIcon,
  IonImg,
  IonItem,
  IonLabel,
  IonLoading,
  IonMenuButton,
  IonPage,
  IonSelect,
  IonSelectOption,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { remove, removeCircle, trash } from "ionicons/icons";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import {
  AdminPerson,
  confirmID,
  Person,
  QDocument,
  Uploader,
  UserDocument,
} from "../../../../components/types";
import { fstore } from "../../../../Firebase";
import { selectAdmin } from "../../../../State/adminState";
import ApprovedCard from "../components/ApprovedCard";
import shape from "../images/p2.jpg";

const Assigned: React.FC = () => {
  const location = useLocation();
  const [assigned, setassigned] = useState<UserDocument[]>([]);
  const [allPapers, setallPapers] = useState<UserDocument[]>([]);
  const [uploader, setUploader] = useState<Uploader[]>([]);
  const [confirm, setconfirm] = useState(false);
  const [loading, setloading] = useState(false);
  const [confirmID, setConfirmID] = useState<confirmID>();
  const [people, setpeople] = useState<Person[]>([]);
  const selectUploaderRef = useRef<HTMLIonSelectElement>(null);
  const admin: AdminPerson = useSelector(selectAdmin);
  const history = useHistory();

  function filter(key: string) {
    // let temp = allPapers;
    // console.log(temp)
    // setassigned(temp);
    // setassigned([...assigned.filter((papers) => papers.assignedTo === key)]);
    // alert("");
  }

  async function unAssignPaper() {
    setloading(true);
    let temp = assigned.filter((p) => p.id === confirmID?.id);
    let paper = temp[0];
    paper.assigned = false;

    // set paper in unassigned collection
    await fstore
      .collection(`faculties`)
      .doc(`FET`)
      .collection(`unassigned`)
      .doc(paper.id)
      .set(paper);

    // remove paper from assigned collection
    await fstore
      .collection(`faculties`)
      .doc(`FET`)
      .collection(`assigned`)
      .doc(paper.id)
      .delete();

    // remove paper from uploader collection
    await fstore
      .collection("uploader")
      .doc(confirmID?.name)
      .collection("papers")
      .doc(paper.id)
      .delete();

    setloading(false);
    history.push("/admin/home")
  }

  function getPeople() {
    fstore
      .collection("uploader")
      .where("faculty", "==", "FET")
      .onSnapshot((documents) => {
        let DOCS: any[] = documents.docs.map((doc) => doc.data());
        setpeople(DOCS);
      });
  }
  useEffect(() => {
    if (location.state) {
      const temp: any = location.state;
      setassigned(temp.assigned);
      setUploader(temp.uploader);
      setallPapers(temp.assigned);
    }
    getPeople();
  }, [location]);
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color={`dark`} className="">
          <IonButtons slot="start">
            <IonMenuButton menu="admin"></IonMenuButton>
          </IonButtons>
          <IonTitle slot="start">Assigned Papers</IonTitle>
          <IonAvatar slot={"end"} className="p-2">
            {admin.photoURL && <IonImg src={admin.photoURL}></IonImg>}
          </IonAvatar>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className="container">
          <div className="container-fluid">
            <IonItem className="ion-margin" fill="outline" shape="round">
              <IonLabel position="floating">Filter by Uploader</IonLabel>
              <IonSelect
                ref={selectUploaderRef}
                onIonChange={(e) => filter(e.detail.value)}
              >
                {people.map((person, index) => {
                  return (
                    <IonSelectOption key={index} value={person.email}>
                      {person.name}
                    </IonSelectOption>
                  );
                })}
              </IonSelect>
            </IonItem>
            {assigned.map((paper, key) => {
              return (
                <IonCard key={key}>
                  <IonItem>
                    <IonCardHeader>
                      <IonCardTitle>
                        {paper.code} {paper.title}{" "}
                      </IonCardTitle>
                      <IonCardSubtitle>
                        {paper.year} {paper.type}
                      </IonCardSubtitle>
                      <IonCardSubtitle className="ion-padding-top">
                        Assigned To: <b>{paper.assignedTo}</b>
                      </IonCardSubtitle>
                    </IonCardHeader>
                    <IonButton
                      color="danger"
                      slot="end"
                      onClick={() => {
                        setConfirmID({
                          name: paper.assignedTo,
                          Course: `${paper.code} ${paper.title} ${paper.year} ${paper.type}`,
                          id: paper.id,
                        });
                        setconfirm(true);
                      }}
                    >
                      <IonIcon icon={removeCircle} slot="icon-only"></IonIcon>
                    </IonButton>
                  </IonItem>
                </IonCard>
              );
            })}
          </div>
          <div>
            {location.state ? (
              " "
            ) : (
              <IonCard mode="ios">
                <IonCardContent className="ion-text-center">
                  <IonCardTitle>No Pending Reviews</IonCardTitle>
                </IonCardContent>
              </IonCard>
            )}
          </div>
        </div>{" "}
        <IonLoading
          isOpen={loading}
          onDidDismiss={() => setloading(false)}
        ></IonLoading>
        <IonAlert
          isOpen={confirm}
          onDidDismiss={() => setconfirm(false)}
          cssClass="my-custom-class"
          header={"Confirm"}
          message={`Unassign <strong>${confirmID?.Course}</strong> from <strong>${confirmID?.name}</strong>?`}
          buttons={[
            {
              text: "Cancel",
              role: "cancel",
              cssClass: "secondary",
              id: "cancel-button",
              handler: (blah) => {
                console.log("Confirm Cancel: blah");
              },
            },
            {
              text: "Okay",
              id: "confirm-button",
              handler: () => {
                console.log("Confirm Okay");
                unAssignPaper();
              },
            },
          ]}
        />
      </IonContent>
    </IonPage>
  );
};

export default Assigned;
