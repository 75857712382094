import { AdminPerson, Person } from "../components/types";
import { Storage } from "@capacitor/storage";


export async function saveUser(person: Person) {

    try {
        Storage.set({ key: `user`, value: JSON.stringify(person) });
        return true
    } catch (err) {
        console.log(err)
        return false
    }
}

export async function getUser(): Promise<Person | null> {

    try {
        const val = (await Storage.get({ key: `user` })).value;
        if (val) {
            return JSON.parse(val)
        }
        else {
            return null
        }
    } catch (err) {
        console.log(err)
        return null
    }
}



export async function savelocalAdmin(person: AdminPerson) {

    try {
        Storage.set({ key: `admin`, value: JSON.stringify(person) });
        return true;
    } catch (err) {
        console.log(err);
        return false;
    }
}


export async function getLocalAdmin(): Promise<AdminPerson | null> {

    try {
        const val = (await Storage.get({ key: `admin` })).value;
        if (val) {
            return JSON.parse(val)
        }
        else {
            return null
        }
    } catch (err) {
        console.log(err)
        return null
    }
}