import {
  IonAlert,
  IonAvatar,
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCol,
  IonGrid,
  IonImg,
  IonNote,
  IonRow,
  IonSpinner,
  IonToast,
  IonToolbar,
} from "@ionic/react";
import { informationCircle } from "ionicons/icons";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { QPaymentRequest, UserDocument } from "../../../../components/types";
import { fstore } from "../../../../Firebase";
import shape from "../images/p1.jpg";

const PaymentRequestCard: React.FC<{ request: QPaymentRequest }> = ({ request }) => {
  const [showToast, setShowToast] = useState(false);
  const [openAlert, setopenAlert] = useState(false);
  const [loading, setloading] = useState(false);
  const [amount, setamount] = useState(0);
  const [approved, setapproved] = useState<UserDocument[]>([]);
  const [disableConfirm, setdisableConfirm] = useState(false);

  const history= useHistory()

  function alertCancel() {
    setopenAlert(false)
    setloading(false)

  }

  async function alertDone() {


    const queries = approved.map(res => {
      fstore.collection(`people`)
        .doc(request.email)
        .collection(`submitted`)
        .doc(res.id).delete()
    })
    fstore.collection(`faculties`)
      .doc(`FET`)
      .collection(`payment-requests`)
      .doc(request.email).delete()
    await Promise.all(queries)

    setopenAlert(false)
    setloading(false)
    setdisableConfirm(true)
    history.goBack()

  }

  function getPapers() {
    setloading(true)
    fstore.collection(`people`)
      .doc(request.email)
      .collection(`submitted`).where(`status`, `==`, `approved`).get().then(res => {
        const doc: any = res.docs.map(doc => doc.data())
        measureBalance(doc)
        setapproved(doc)
        setopenAlert(true)
      })

  }

  function measureBalance(papers: UserDocument[]) {
    let sum = 0;
    papers.map(pap => {
      if (pap.status == `approved`) {
        sum += 50;
      }
    })
    setamount(sum)
  }

  return (
    <IonCard className={`card`} mode={`ios`}>
      <IonCardContent>
        <IonGrid>
          <IonRow>
            <IonCol>
              {!loading ? <IonAvatar style={{ [`margin`]: `auto` }} >
                {request.photoURL ? <IonImg src={request.photoURL}></IonImg> : <IonImg src={shape}></IonImg>}
              </IonAvatar> : <IonSpinner color={`dark`}></IonSpinner>}
            </IonCol>
            <IonCol size={`12`} sizeSm={`10`} sizeMd={`6`}>
              <IonCardTitle >{request.user_name}</IonCardTitle>
              <IonNote  >5 hours Ago</IonNote>
              <div  >
                <IonCardSubtitle  >{request.tel}</IonCardSubtitle>
              </div>
            </IonCol>
            <IonCol>
              <IonButton disabled={disableConfirm} color="dark" onClick={() => { getPapers() }}>
                Confirm Payment
            </IonButton>
            </IonCol>
          </IonRow>
          <IonAlert buttons={[{ text: `cancel`, handler: alertCancel }, { text: `Done`, handler: alertDone }]} subHeader={`send funds to ${request.momoNumber} | MOMO name is ${request.momoName}`} header={`Send Funds ${amount}`} onDidDismiss={() => setopenAlert(false)} isOpen={openAlert}></IonAlert>


        </IonGrid>

      </IonCardContent>

      <IonToast
        isOpen={showToast}
        onDidDismiss={() => setShowToast(false)}
        message="Payment Confirmed"
        icon={informationCircle}
        duration={500}
        color="success"
      />

    </IonCard>
  );
};

export default PaymentRequestCard;
