import {
  IonAvatar,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonImg,
  IonMenuButton,
  IonPage,
  IonRow,
  IonSkeletonText,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { add, notificationsOutline, trashOutline } from "ionicons/icons";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { Person } from "../../../components/types";
import { getUser } from "../../../State/local";
import {
  NotifState,
  selectNotifications,
} from "../../../State/notificationState";
import { selectUser, updateUser } from "../../../State/userstate";
import ReactTimeAgo from "react-time-ago";

function Notifications() {
  const user: Person = useSelector(selectUser);
  const [balance, setbalance] = useState<number>(0);
  const notifs: NotifState = useSelector(selectNotifications);

  const generalNotifs = notifs.general;
  const specificNotifs = notifs.all;
  const history = useHistory();

  const dispatch = useDispatch();

  useEffect(() => {
    if (!user?.email) {
      getUserInfo();
    } else {
      // getNotifications(user, (res) => {
      //     setnotifs([...specificNotifs, ...res])
      //     setnoNotifs([...specificNotifs, ...res].length<=0)
      //     setgeneralNotifs([...res])
      // }, (res) => {
      //     setnotifs([...generalNotifs, ...res])
      //     setspecificNotifs([...res])
      //     setnoNotifs([...generalNotifs, ...res].length<=0)
      // });
    }
  }, []);

  async function getUserInfo() {
    const user = await getUser();
    if (user && user.email) {
      dispatch(updateUser(user));
    } else {
      history.push(`/login`);
    }
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color={`dark`}>
          <IonTitle>
            Your Notifications{" "}
            {notifs.all.length > 0 ? `(${notifs.all.length})` : ``}
          </IonTitle>
          <IonMenuButton slot={`end`}></IonMenuButton>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {true && (
          <IonToolbar className={`ion-padding`}>
            {notifs.all.length <= 0 && (
              <div style={{ paddingTop: `20vh` }}>
                <IonGrid>
                  <IonRow>
                    <IonCol></IonCol>
                    <IonCol size={`10`} sizeSm={`8`} sizeMd={`6`}>
                      <IonCard
                        style={{ margin: `auto` }}
                        className={`card`}
                        mode={`ios`}
                      >
                        <IonCardContent className={`text-center`}>
                          <div className="ion-padding ">
                            <IonIcon
                              size={`large`}
                              icon={notificationsOutline}
                            ></IonIcon>
                          </div>
                          No Notifications
                        </IonCardContent>
                      </IonCard>
                    </IonCol>
                    <IonCol></IonCol>
                  </IonRow>
                </IonGrid>
              </div>
            )}
            {/* {notifs.all.length <= 0 &&
              [1, 1, 1].map((res) => (
                <div className={`ion-padding`}>
                  <IonSkeletonText
                    className={`card`}
                    animated
                    style={{
                      height: `170px`,
                      width: `100%`,
                      borderRadius: `10px`,
                    }}
                  ></IonSkeletonText>
                </div>
              ))} */}
            {generalNotifs.length > 0 && (
              <IonToolbar>
                <h2>General</h2>
              </IonToolbar>
            )}
            {generalNotifs.map((res, index) => {
              return (
                <IonCard key={index} mode={`ios`}>
                  <IonToolbar>
                    <h4>{res.title}</h4>
                    <ReactTimeAgo date={res.timestamp}></ReactTimeAgo>
                    <p>{res.description}</p>

                    <IonButtons>
                      <IonButton>
                        <IonIcon icon={trashOutline}></IonIcon>
                      </IonButton>
                    </IonButtons>
                  </IonToolbar>
                </IonCard>
              );
            })}
            {specificNotifs.length > 0 && (
              <IonToolbar>
                <h2>Specific</h2>
              </IonToolbar>
            )}
            {specificNotifs.map((res, index) => {
              return (
                <IonCard key={index} mode={`ios`}>
                  <IonToolbar>
                    <h4>{res.title}</h4>
                    <ReactTimeAgo date={res.timestamp}></ReactTimeAgo>
                    <p>{res.description}</p>

                    <IonButtons>
                      <IonButton>
                        <IonIcon icon={trashOutline}></IonIcon>
                      </IonButton>
                    </IonButtons>
                  </IonToolbar>
                </IonCard>
              );
            })}
          </IonToolbar>
        )}
      </IonContent>
    </IonPage>
  );
}
export default Notifications;
