import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonChip,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonPage,
  IonRow,
  IonText,
  IonTextarea,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import {
  call,
  close,
  closeCircle,
  logoWhatsapp,
  timeSharp,
} from "ionicons/icons";
import emailjs from "@emailjs/browser";
import React, { useEffect, useRef, useState } from "react";
import contact from "../images/contact.svg";

const Contact: React.FC = () => {
  const email = useRef<HTMLIonInputElement>(null);
  const messageSubject = useRef<HTMLIonInputElement>(null);
  const username = useRef<HTMLIonInputElement>(null);
  const messageBody = useRef<HTMLIonTextareaElement>(null);
  const [sent, setSent] = useState(false);

  useEffect(() => {
    emailjs.init("user_c53fw8KxQvmVLFDZHvn0U");
  }, []);

  function sendMail() {
    //   collect Data
    let templateParams = {
      name: username.current?.value,
      email: email.current?.value,
      message: messageBody.current?.value,
      message_subject: messageSubject.current?.value,
    };

    // send mail
    emailjs.send("service_r9s420l", "template_26ygn9j", templateParams).then(
      function (response) {
        console.log("SUCCESS!", response.status, response.text);
        setSent(true);
      },
      function (error) {
        console.log("FAILED...", error);
      }
    );
  }
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="dark">
          <IonButtons slot="start">
            <IonBackButton defaultHref="/home" />
          </IonButtons>
          <IonTitle> Contact Us</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent color="clear">
        <div className="container-io container">
          <IonIcon icon={contact} style={{ fontSize: "200px" }}></IonIcon>
          {sent ? (
            <IonCard color="success">
              <IonItem color="success">
                <IonText slot="start">Message Sent</IonText>
                <IonIcon
                  icon={closeCircle}
                  size="large"
                  slot="end" 
                  onClick={()=>{setSent(false)}}
                ></IonIcon>
              </IonItem>
            </IonCard>
          ) : (
            " "
          )}
          <IonCard>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                sendMail();
              }}
            >
              <IonGrid>
                <div className="text-end">
                  <IonChip color="success">
                    <IonLabel>Support</IonLabel>
                    <IonIcon icon={logoWhatsapp}></IonIcon>
                  </IonChip>
                  <IonChip color="primary">
                    <IonLabel>Call</IonLabel>
                    <IonIcon icon={call}></IonIcon>
                  </IonChip>
                </div>
                <IonCardHeader className="text-start">
                  <IonCardTitle>
                    <IonText>Contact</IonText>{" "}
                    <IonText color="success">Us</IonText>{" "}
                  </IonCardTitle>
                  <IonCardSubtitle>
                    Fill the contact the submit the contact form
                  </IonCardSubtitle>
                </IonCardHeader>
                <IonCardContent>
                  <IonRow>
                    <IonCol>
                      <IonItem lines="full">
                        <IonLabel color="dark" position="floating">
                          Your Name
                        </IonLabel>
                        <IonInput
                          type="text"
                          required
                          ref={username}
                        ></IonInput>
                      </IonItem>
                    </IonCol>
                    <IonCol>
                      <IonItem lines="full">
                        <IonLabel color="dark" position="floating">
                          Your Email
                        </IonLabel>
                        <IonInput type="email" required ref={email}></IonInput>
                      </IonItem>
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol>
                      <IonItem lines="full">
                        <IonLabel color="dark" position="floating">
                          Subject
                        </IonLabel>
                        <IonInput
                          type="text"
                          required
                          ref={messageSubject}
                        ></IonInput>
                      </IonItem>
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol>
                      <IonItem lines="full">
                        <IonLabel color="dark" position="floating">
                          Write Message
                        </IonLabel>
                        <IonTextarea required ref={messageBody}></IonTextarea>
                      </IonItem>
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol></IonCol>
                    <IonCol>
                      <IonButton color="success" shape="round" type="submit">
                        Submit
                      </IonButton>
                    </IonCol>
                    <IonCol></IonCol>
                  </IonRow>
                </IonCardContent>
              </IonGrid>
            </form>
          </IonCard>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Contact;
