import { IonModal, IonHeader, IonToolbar, IonTitle, IonContent, IonCardSubtitle, IonLabel, IonItem, IonInput, IonSelect, IonSelectOption, IonButton, IonProgressBar, useIonAlert, IonAvatar, IonImg } from '@ionic/react'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { fstore } from '../Firebase'
import { createPerson } from '../Firebase/login'
import { saveUser } from '../State/local'
import { selectUser, updateUser } from '../State/userstate'
import { Person, QPaymentRequest } from './types'

interface AddDetailModalProps {
    isOpen: boolean,
    onDidDismiss: () => void
}
const PaymentRequestModal: React.FC<AddDetailModalProps> = function ({ onDidDismiss, isOpen }) {

    const [number, setnumber] = useState(``)
    const [type, settype] = useState<`orange` | `mtn`>(`orange`)
    const user: Person = useSelector(selectUser);
    const [loading, setloading] = useState(false)
    const [momoName, setmomoName] = useState(user.name)

    const history = useHistory();

    const dispatch = useDispatch()

    useEffect(() => {
        console.log(user)
    }, [])



    async function submit(e: any) {
        if (!type) {
            alert(`Mobile money option`)
            return;
        }
        e.preventDefault();
        const req: QPaymentRequest = {
            email: user.email,
            momoName: momoName,
            momoNumber: number,
            photoURL: user.photoURL || ``,
            timestamp: Date.now(),
            user_name: user.name,
            department: user.department || ``,
            faculty: user.faculty || ``,
            tel: user.tel || ``
        }
        setloading(true)

        try {

            await fstore.collection(`faculties`)
                .doc(`FET`)
                .collection(`payment-requests`).doc(user.email).set(req)

            fstore.collection(`people`).doc(user.email).update({
                momoName: momoName,
                momoNumber: number,
            })
            onDidDismiss()
            alert(`Successfully made payment request. your payments will be sent in atmost 48 hours`)

        } catch (err) {

            //debug
            alert(`err paymentrequestModal 66 : \n ${err}`)
        }
        setloading(false)


    }
    return (
        <IonModal onDidDismiss={() => onDidDismiss()} isOpen={isOpen}>
            <IonHeader>
                <IonToolbar >
                    <IonTitle> Please confirm your request</IonTitle>
                    {user.photoURL && <IonAvatar style={{ transform: `scale(0.8)` }} slot={`end`}>
                        <IonImg src={user.photoURL}></IonImg>
                    </IonAvatar>}
                </IonToolbar>
            </IonHeader>
            <IonContent>
                {loading && <IonProgressBar color={`success`} className={`ion-padding`} type={`indeterminate`}></IonProgressBar>}

                <form onSubmit={submit}>
                    <IonToolbar style={{ minHeight: `100vh` }} className={`ion-padding`} color={`light`}>
                        <IonCardSubtitle>Please check the information below <IonLabel color={`danger`}>*</IonLabel></IonCardSubtitle>
                        <IonItem disabled={loading} color={`none`}>
                            <IonLabel position={`floating`}>Enter momo number</IonLabel>
                            <IonInput value={number} required onIonChange={(e) => setnumber(e.detail.value || ``)}></IonInput>
                        </IonItem>
                        <IonItem disabled={loading} color={`none`}>
                            <IonLabel position={`floating`}>Enter momo name</IonLabel>
                            <IonInput value={momoName} required onIonChange={(e) => setmomoName(e.detail.value || ``)}></IonInput>
                        </IonItem>
                        <IonItem disabled={loading} color={`none`}>
                            <IonLabel position={`floating`}>Momo Type</IonLabel>
                            <IonSelect onIonChange={(e) => settype(e.detail.value || ``)} value={type} interface={`action-sheet`}>
                                <IonSelectOption value={`mtn`}>MTN Mobile Money</IonSelectOption>
                                <IonSelectOption value={`orange`}>Orange Money</IonSelectOption>
                            </IonSelect>
                        </IonItem>
                        <br />
                        <br />
                        <IonToolbar style={{ textAlign: `right` }} className={`ion-padding-horizontal`} color={`none`}>
                            <IonButton disabled={loading} type={`submit`} color={`success`}>DONE</IonButton>
                        </IonToolbar>
                    </IonToolbar>
                </form>
            </IonContent>
        </IonModal>
    )
}

export default PaymentRequestModal
