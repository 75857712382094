import {
  IonAvatar,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardTitle,
  IonContent,
  IonHeader,
  IonImg,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { AdminPerson, QDocument, UserDocument } from "../../../../components/types";
import { selectAdmin } from "../../../../State/adminState";
import ApprovedCard from "../components/ApprovedCard";
import shape from "../images/p2.jpg";

const Approved: React.FC = () => {
  const location = useLocation()
  const [approved, setapproved] = useState<UserDocument[]>([])
  const admin:AdminPerson = useSelector(selectAdmin)

  
  useEffect(() => {
    if (location.state) {
      const temp: any = location.state;
      setapproved(temp)
    } 
  }, [location])
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color={`dark`} className="">
          <IonButtons slot="start">
            <IonMenuButton menu="admin"></IonMenuButton>
          </IonButtons>
          <IonTitle slot="start">Approved Papers</IonTitle>
          <IonAvatar slot={"end"} className="p-2">
          {admin.photoURL &&  <IonImg src={admin.photoURL}></IonImg>}
          </IonAvatar>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className="container">
          <div className="container-fluid">
            {
              approved.map(paper => {
                return (
                  <ApprovedCard paper={paper}></ApprovedCard>
                )
              })
            }
          </div>
          <div>
            {!location.state ? (
              " "
            ) : (
              <IonCard mode="ios">
                <IonCardContent className="ion-text-center">
                  <IonCardTitle>No Pending Reviews</IonCardTitle>
                </IonCardContent>
              </IonCard>
            )}
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Approved;
