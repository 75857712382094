import { IonModal, IonHeader, IonToolbar, IonTitle, IonContent, IonCardSubtitle, IonLabel, IonItem, IonInput, IonSelect, IonSelectOption, IonButton, IonProgressBar, useIonAlert, IonAvatar, IonImg } from '@ionic/react'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { createPerson } from '../Firebase/login'
import { saveUser } from '../State/local'
import { selectUser, updateUser } from '../State/userstate'
import { Person } from './types'

interface AddDetailModalProps {
    isOpen: boolean,
    onDidDismiss: () => void
}
const AddDetailModal: React.FC<AddDetailModalProps> = function ({ onDidDismiss, isOpen }) {
    const [number, setnumber] = useState(``)
    const [department, setdepartment] = useState(``)
    const [faculty, setfaculty] = useState(``)
    const user: Person = useSelector(selectUser);
    const [loading, setloading] = useState(false)
    const history = useHistory();

    const dispatch = useDispatch()

    useEffect(() => {
    }, [])



    async function submit(e: any) {
        if (!department || !faculty) {
            alert(`Please select your Faculty and Department`)
            return;
        }
        e.preventDefault();
        const temp_person: Person = {
            ...user,
            tel: number,
            department,
            faculty,
            school: `UB`,
        }
        setloading(true)

        try {
            await createPerson(temp_person)
            dispatch(updateUser(temp_person));
            saveUser(temp_person)
            history.push(`/home`)
            onDidDismiss()


        } catch (err) {

            //debug
            alert(`err adddetailmodal 51 : \n ${err}`)
        }
        setloading(false)


    }
    return (
        <IonModal onDidDismiss={() => onDidDismiss()} isOpen={isOpen}>
            <IonHeader>
                <IonToolbar >
                    <IonTitle>  Please Add the Following Information</IonTitle>
                   {user.photoURL&& <IonAvatar style={{transform:`scale(0.8)`}} slot={`end`}>
                        <IonImg src={user.photoURL}></IonImg>
                    </IonAvatar>}
                </IonToolbar>
            </IonHeader>
            <IonContent>
                {loading && <IonProgressBar color={`success`} className={`ion-padding`} type={`indeterminate`}></IonProgressBar>}

                <form onSubmit={submit}>
                    <IonToolbar style={{ minHeight: `100vh` }} className={`ion-padding`} color={`light`}>
                        <IonCardSubtitle>Pease fill in the fields below <IonLabel color={`danger`}>*</IonLabel></IonCardSubtitle>
                        <IonItem disabled={loading} color={`none`}>
                            <IonLabel position={`floating`}>Enter Phone number</IonLabel>
                            <IonInput required onIonChange={(e) => setnumber(e.detail.value || ``)}></IonInput>
                        </IonItem>
                        <IonItem disabled={loading} color={`none`}>
                            <IonLabel position={`floating`}>Faculty</IonLabel>
                            <IonSelect onIonChange={(e) => setfaculty(e.detail.value || ``)} value={faculty} interface={`action-sheet`}>
                                <IonSelectOption value={`FET`}>Faculty of Engineering (FET)</IonSelectOption>
                            </IonSelect>
                        </IonItem>
                        <IonItem disabled={loading} color={`none`}>
                            <IonLabel position={`floating`}>Department</IonLabel>
                            <IonSelect onIonChange={(e) => setdepartment(e.detail.value || ``)} value={department} interface={`action-sheet`}>
                                <IonSelectOption value={`CE`}>Computer Engineering</IonSelectOption>
                                <IonSelectOption value={`CVE`}>Civil Engineering</IonSelectOption>
                                <IonSelectOption value={`EE`}>Electrical Engineering</IonSelectOption>
                            </IonSelect>
                        </IonItem>
                        <br />
                        <br />
                        <IonToolbar style={{ textAlign: `right` }} className={`ion-padding-horizontal`} color={`none`}>
                            <IonButton disabled={loading} type={`submit`} color={`success`}>DONE</IonButton>
                        </IonToolbar>
                    </IonToolbar>
                </form>
            </IonContent>
        </IonModal>
    )
}

export default AddDetailModal
