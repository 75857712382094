import {
  IonAvatar,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardTitle,
  IonContent,
  IonHeader,
  IonImg,
  IonLabel,
  IonMenuButton,
  IonPage,
  IonSegment,
  IonSegmentButton,
  IonSlide,
  IonSlides,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { AdminPerson, QPaymentRequest, UserDocument } from "../../../../components/types";
import { selectAdmin } from "../../../../State/adminState";
import PaymentRequestCard from "../components/PaymentRequestCard";
import shape from "../images/p2.jpg";

const PaymentRequest: React.FC = () => {
  const slidesRef = React.useRef<HTMLIonSlidesElement>(null);
  const location = useLocation()
  const [paymentReq, setpaymentReq] = useState<QPaymentRequest[]>([])
  const admin:AdminPerson = useSelector(selectAdmin)

  
  function slideTo(index: number) {
    slidesRef.current?.slideTo(index);
  }

  useEffect(() => {
    if (location.state) {
      const temp: any = location.state;
      setpaymentReq(temp)
    }
  }, [location])

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="dark">
          <IonButtons slot="start">
            <IonMenuButton menu="admin"></IonMenuButton>
          </IonButtons>
          <IonTitle slot="start">Payment Requests</IonTitle>
          <IonAvatar slot={"end"} className="p-2">
          {admin.photoURL&&  <IonImg src={admin.photoURL}></IonImg>}
          </IonAvatar>
        </IonToolbar>
        <IonSegment style={{"--background":"var(--ion-color-light-tint)"}} value={"pending"} color="success">
          <IonSegmentButton value="pending" onClick={() => {
            slideTo(0)
          }}>
            <IonLabel>Pending</IonLabel>
          </IonSegmentButton>
          <IonSegmentButton value="approved" onClick={() => {
            slideTo(1)
          }}>
            <IonLabel>Approved</IonLabel>
          </IonSegmentButton>
        </IonSegment>
      </IonHeader> 
      <IonContent>
      <div>
            {!location.state ? (
              " "
            ) : (
              <IonCard>
                <IonCardContent className="ion-text-center">
                  <IonCardTitle>No Request</IonCardTitle>
                </IonCardContent>
              </IonCard>
            )}
          </div>
        <IonSlides ref={slidesRef}>

          {/* slide for pending */}
          <IonSlide>
            <div className="container">
              <div className="container-fluid">
               {
                 paymentReq.map((req,i)=>{
                   return(
                    <PaymentRequestCard  request={req} key={i} ></PaymentRequestCard>
                   )
                 })
               }
              </div>
            </div>
          </IonSlide>

          {/* slide for approved */}
          <IonSlide>
            <div className="container">
              <div className="container-fluid">
                 
              </div>
            </div>{" "}
          </IonSlide>
        </IonSlides>
      </IonContent>
    </IonPage>
  );
};

export default PaymentRequest;
