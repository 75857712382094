import PDFicon from "./PDFicon.png";
import banner from "./banner.png";
import Honorine from "./Honorine.jpeg";
import pdfblur from "./pdfblur.png";
import Becky from "./becky.jpg";
import Daniel from "./daniel.jpeg";
import logo from "./quesersLogoTransparent.png";
import question from './question.svg'
import answer from './answer.svg'



export const local_images={
    PDFicon,
    banner,
    Honorine,
    pdfblur,
    Becky,
    Daniel,
    logo
}

export const local_icons ={
    question,
    answer
}