import {
    IonBadge,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardTitle,
    IonChip,
    IonCol,
    IonContent,
    IonFab,
    IonFabButton,
    IonGrid,
    IonHeader,
    IonIcon,
    IonItem,
    IonLabel,
    IonMenuButton,
    IonNote,
    IonPage,
    IonRippleEffect,
    IonRow,
    IonSearchbar,
    IonSkeletonText,
    IonTitle,
    IonToolbar,
  } from "@ionic/react";
  import {
    add,
    alarmOutline,
    callOutline,
    chevronForward,
    chevronForwardCircle,
    logoWhatsapp,
    mailOutline,
    notifications,
  } from "ionicons/icons";
  import React, { useEffect, useState } from "react";
  import { useDispatch, useSelector } from "react-redux";
  import { useHistory } from "react-router";
  import Header from "../../../components/Header";
  import { Person, UserDocument } from "../../../components/types";
  import { fstore } from "../../../Firebase";
  import { getSubmitted } from "../../../Firebase/user";
  import { local_images } from "../../../images/images";
  import { getUser } from "../../../State/local";
  import { updatePapers } from "../../../State/papersState";
  import { selectUser, updateUser } from "../../../State/userstate";
  
  function AllSolutions() {
    const person: Person = useSelector(selectUser);
    const [documents, setdocuments] = useState<UserDocument[]>([]);
    const [noPapers, setnoPapers] = useState<boolean>(false);
    const history = useHistory();
    const dispatch = useDispatch();
  
    useEffect(() => {
      if (person) {
        getUserPapers();
      }
    }, []);
  
    useEffect(() => {
      getUserInfo();
    }, []);
  
    async function getUserInfo(): Promise<Person | null> {
      const user = await getUser();
      if (user && user.email) {
        dispatch(updateUser(user));
        return user;
      }
      return null;
    }
  
    async function getUserPapers() {
      const val = await getUserInfo();
      if (!person.email) {
        if (!val) {
          history.push(`/login`);
          return;
        }
      }
  
      let personVal = !person?.email ? val : person;
      if (!personVal?.email) {
        alert(`person does not exist`);
        return;
      }
      setnoPapers(false);
  
      fstore
        .collection(`people/${personVal.email}/submitted`)
        .onSnapshot((res) => {
          const docs: any[] = res.docs.map((doc) => doc.data());
          setdocuments(docs);
          dispatch(updatePapers(docs || []));
          setnoPapers(docs.length <= 0);
        });
    }
  
    return (
      <IonPage>
        <Header papers={documents}></Header>
        <IonContent>
          <IonToolbar color={`light`} className={`ion-padding`}>
            <IonCardHeader>
              <IonCardTitle>Recent Papers</IonCardTitle>
            </IonCardHeader>
            <IonGrid>
              <IonRow className="ion-justify-content-center">
                {documents.length <= 0 &&
                  !noPapers &&
                  [1, 1, 1].map((res, index) => (
                    <IonCol
                      key={index}
                      size={`12`}
                      sizeMd={`6`}
                      sizeLg={`3`}
                      sizeXl={`2`}
                    >
                      <IonSkeletonText
                        animated
                        style={{
                          height: `250px`,
                          width: `80%`,
                          borderRadius: `10px`,
                        }}
                      ></IonSkeletonText>
                    </IonCol>
                  ))}
                {documents.map((doc, index) => (
                  <IonCol
                    key={index}
                    size={`12`}
                    sizeMd={`6`}
                    sizeLg={`3`}
                    sizeXl={`2`}
                  >
                    <IonCard
                      key={index}
                      button
                      onClick={() => history.push(`/paper-detail`, doc)}
                      style={{ width: `100%` }}
                      mode={`ios`}
                    >
                      <div className="card ion-activatable ripple-parent">
                        <img
                          style={{
                            height: "200px",
                            width: "100%",
                            objectFit: "cover",
                          }}
                          src={local_images.pdfblur}
                          alt=""
                          className="card-img"
                        />
                        <IonLabel mode={`md`}>
                          <div className={`container-fluid ion-padding-vertical`}>
                            <IonCardTitle style={{ fontSize: `16px` }}>
                              {doc.code} {doc.title} {doc.type} {doc.year}
                            </IonCardTitle>
                            <div>
                              <br />
                              <IonChip
                                outline
                                mode={`md`}
                                slot={`end`}
                                color={
                                  doc.status == `pending`
                                    ? "warning"
                                    : doc.status == `approved`
                                    ? `success`
                                    : `danger`
                                }
                              >
                                {doc.status}
                              </IonChip>
                            </div>
                          </div>
                          <IonRippleEffect></IonRippleEffect>
                        </IonLabel>
                      </div>
                    </IonCard>
                  </IonCol>
                ))}
              </IonRow> 
            </IonGrid>
          </IonToolbar>
          <IonToolbar className={`ion-padding ion-margin`}>
            <IonButtons className={`row`} slot={`end`}>
              <IonButton
                target={`__blank`}
                href={`mailto:quesersteam@gmail.com`}
                className={`col`}
              >
                <IonIcon
                  slot={`start`}
                  color={`danger`}
                  icon={mailOutline}
                ></IonIcon>
                <IonLabel>send us a mail</IonLabel>
              </IonButton>
              <IonButton
                target={`__blank`}
                href={`https://chat.whatsapp.com/LchBn6cw40YI7VL6G8Om2r`}
                className={`col`}
              >
                <IonIcon
                  slot={`start`}
                  color={`success`}
                  icon={logoWhatsapp}
                ></IonIcon>
                <IonLabel>Message Us</IonLabel>
              </IonButton>
              <IonButton className={`col`}>
                <IonIcon
                  slot={`start`}
                  color={`tertiary`}
                  icon={callOutline}
                ></IonIcon>
                <IonLabel>call Us</IonLabel>
              </IonButton>
            </IonButtons>
          </IonToolbar>
          <div style={{ height: `10vh` }}></div>
        </IonContent>
        <IonFab
          style={{ transform: `translate(-20px, -20px)` }}
          horizontal={`end`}
          vertical={`bottom`}
        >
          <IonFabButton routerLink={`/create`} color={`dark`}>
            <IonIcon icon={add}></IonIcon>
          </IonFabButton>
        </IonFab>
      </IonPage>
    );
  }
  
  export default AllSolutions;
  