import firebase from "firebase";
  


const firebaseConfig = {

    apiKey: "AIzaSyDL3_G4fZ3mpGE6RxbETznz71d7NUtqj3c",
    authDomain: "queserscommunityplatform.firebaseapp.com",
    projectId: "queserscommunityplatform",
    storageBucket: "queserscommunityplatform.appspot.com",
    messagingSenderId: "162471734608",
    appId: "1:162471734608:web:b964dc5656d0614b169055",
    measurementId: "G-XBVK1QXN7H"

};


const app = firebase.initializeApp(firebaseConfig)

const fstore= app.firestore();
const database = app.database()
const auth = app.auth()
const storage = app.storage()
 
export {   app, fstore, auth, database, storage};





