import { notifications } from 'ionicons/icons'
import { getNotifications } from '../Firebase/user';
import { IonAvatar, IonBadge, IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonImg, IonLabel, IonMenuButton, IonPage, IonRow, IonSearchbar, IonSkeletonText, IonTitle, IonToolbar } from '@ionic/react'
import { add, notificationsOutline } from 'ionicons/icons'
import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router'
import { Person, QNotification, UserDocument } from '../components/types'
import { getUser } from '../State/local'
import { selectUser, updateUser } from '../State/userstate'
import { selectNotifications, updateGeneral, updateSpeific,NotifState } from '../State/notificationState';

const Header:React.FC<{papers:UserDocument[]}> = function ({papers}) {
    const user: Person = useSelector(selectUser)
    const [balance, setbalance] = useState<number>(0) 
    const [generalNotifs, setgeneralNotifs] = useState<QNotification[]>([])
    const [specificNotifs, setspecificNotifs] = useState<QNotification[]>([])
    const notifs:NotifState = useSelector(selectNotifications)
    const history = useHistory()

    const dispatch = useDispatch()

    useEffect(() => {
        if (!user?.email) {
            getUserInfo()
        } else {
            getNotifications(user, (res) => {
                // setnotifs([...specificNotifs, ...res])
                // setnoNotifs([...specificNotifs, ...res].length<=0)
                // setgeneralNotifs([...res])
                dispatch(updateGeneral(res))
            }, (res) => {
                // setnotifs([...generalNotifs, ...res])
                // setspecificNotifs([...res])
                // setnoNotifs([...generalNotifs, ...res].length<=0)
                dispatch(updateSpeific(res))
            });
        }
        measureBalance()

    }, [])



    async function getUserInfo() {
        const user = await getUser()
        if (user && user.email) {
            dispatch(updateUser(user))
        }
        else {
            history.push(`/login`)
        }
    }

    function measureBalance(){
        let sum=0;
         papers.map(pap=>{
            if( pap.status==`approved`){
                sum+=50;
            }
         })
         setbalance(sum)
    }

    useEffect(() => {
        measureBalance()
    }, [papers])
   
    return (
        <IonHeader>
            <IonToolbar color={`dark`}> 
                <IonGrid style={{ padding: 0, margin: 0 }}>
                    <IonRow>
                        <IonCol className={`align-self-center`} style={{ padding: 0, margin: 0 }}>
                            <IonTitle> <IonLabel color={`success`}>Quesers </IonLabel>Community Platform</IonTitle>
                        </IonCol>
                        <IonCol sizeLg={`6`} size={`12`} className={`align-self-center`} style={{ padding: 0, margin: 0 }}>
                            <IonToolbar color={`none`}>
                                <IonSearchbar style={{ transform: `translate(0,8px)` }} mode={`ios`}  ></IonSearchbar>
                                <IonButtons slot={`end`} >
                                    <IonButton routerLink={`/account`}>
                                       {balance} FCFA
                                    </IonButton>
                                    <IonButton routerLink={`/notifications`}>
                                        <IonIcon icon={notifications}></IonIcon>
                                        <IonBadge color={`medium`}> {notifs.all.length}</IonBadge>
                                    </IonButton>
                                    <IonMenuButton slot='end' autoHide={false} menu="main"></IonMenuButton>
                                </IonButtons>
                            </IonToolbar>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonToolbar>
        </IonHeader>
    )
}

export default Header
