import { IonButton, IonCol, IonContent, IonGrid, IonHeader, IonInput, IonItem, IonLabel, IonLoading, IonModal, IonRow, IonSelect, IonSelectOption, IonTitle, IonToolbar, useIonViewDidEnter } from '@ionic/react'
import React, { useState } from 'react'
import { QDocument } from '../../../../components/types'
import { fstore } from '../../../../Firebase'

function AddModal(props: { onDidDismiss: () => void, isOpen: boolean, doc?: QDocument }) {
    const { isOpen, onDidDismiss, doc } = props
    const [newPaper, setnewPaper] = useState<QDocument>(doc || {
        available: true,
        code: ``,
        cost: `50`,
        create_timestamp: 0,
        department: ``,
        faculty: ``,
        title: ``,
        type: `ca`,
        year: `2021`,
        id: ``
    })

    const [paper_types, setpaper_types] = useState<("ca" | "exams" | "resit" | "catchup-ca")[]>([])

    const [loading, setloading] = useState(false)

    async function uploadPaper() {
         
        if (paper_types.length <= 0) {
            setloading(false)
            alert(`please fill in the accepted types`)
            return;
        }
        if (newPaper) {
            try {
                const queries = paper_types.map((type) => {
                    const id = newPaper.year + newPaper.code + type;
                    const cost = type == `exams` ? `100` : `50`;
                    const finalVersion: QDocument = { ...newPaper, type, id, cost }
                    console.log(id);
                    
                    fstore.collection(`faculties`).doc(`FET`).collection(`papers`).doc(id).set(finalVersion)
                })
                await Promise.all(queries)
                onDidDismiss()
            }
            catch (err) {
                alert(err)
            }
            setloading(false)
        }
    }


    function submit(e: any) {
        e.preventDefault()
        setloading(true)
        uploadPaper()


    }

    return (
        <IonModal onWillPresent={() => {
            if (doc) {
                setnewPaper(doc)
            }
        }} onDidPresent={() => {
            if (doc) {
                setTimeout(() => {
                    setnewPaper(doc)
                }, 500);
            }
        }} isOpen={isOpen} onDidDismiss={onDidDismiss}>
            <IonHeader>
                <IonToolbar color={`dark`}>
                    <IonTitle>
                        {doc?.title ? `Edit Paper` : `Add Paper`}
                    </IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonLoading onDidDismiss={() => setloading(false)} isOpen={loading} spinner={`circles`} message={`Loading`}></IonLoading>
            <IonContent>
                <IonGrid>
                    <IonRow>
                        <IonCol></IonCol>
                        <IonCol size={`10`} >
                            <h3>Please fill in the following</h3>
                            <form onSubmit={submit} className="ion-padding">
                                <IonItem disabled={loading}>
                                    <IonLabel position={`floating`}>Title</IonLabel>
                                    <IonInput required onIonChange={(e) => setnewPaper({ ...newPaper, title: e.detail.value || `` })} value={newPaper.title}></IonInput>
                                </IonItem>
                                <IonItem disabled={loading || !!doc}>
                                    <IonLabel position={`floating`}>code</IonLabel>
                                    <IonInput required onIonChange={(e) => setnewPaper({ ...newPaper, code: e.detail.value || `` })} value={newPaper.code}></IonInput>
                                </IonItem>
                                <IonItem disabled={loading || !!doc}>
                                    <IonLabel position={`floating`}>year</IonLabel>
                                    <IonInput required onIonChange={(e) => setnewPaper({ ...newPaper, year: e.detail.value || `` })} value={newPaper.year}></IonInput>
                                </IonItem>
                                <IonItem disabled={loading || !!doc}>
                                    <IonLabel position={`floating`}>accept the following types</IonLabel>
                                    <IonSelect onIonChange={(e) => setpaper_types(e.detail.value || [])} value={paper_types || [`ca`]} multiple={true}>
                                        <IonSelectOption value={`ca`}>CA</IonSelectOption>
                                        <IonSelectOption value={`exams`}>Exams</IonSelectOption>
                                        <IonSelectOption value={`resit`}>Resit</IonSelectOption>
                                    </IonSelect>
                                </IonItem>
                                <IonItem disabled>
                                    <IonLabel position={`floating`}>Faculty</IonLabel>
                                    <IonInput value={`FET`}></IonInput>
                                </IonItem>
                                <IonItem disabled={loading}>
                                    <IonLabel position={`floating`}>Department</IonLabel>
                                    <IonSelect onIonChange={(e) => setnewPaper({ ...newPaper, department: e.detail.value || `` })} value={newPaper.department} >
                                        <IonSelectOption>Computer Engineering</IonSelectOption>
                                        <IonSelectOption>Electrical Engineering</IonSelectOption>
                                        <IonSelectOption>Civil Engineering</IonSelectOption>
                                    </IonSelect>
                                </IonItem>

                                <br />
                                <br />
                                <div className="ion-margin-top">
                                    <IonToolbar>
                                        <IonButton disabled={loading} type={`submit`} color={`dark`}>  {doc?.title ? `Save Changes` : `Add Paper`}</IonButton>
                                    </IonToolbar>
                                </div>
                            </form>
                        </IonCol>
                        <IonCol></IonCol>
                    </IonRow>
                    <div style={{ height: `70px` }}></div>
                </IonGrid>
            </IonContent>
        </IonModal>
    )
}

export default AddModal

