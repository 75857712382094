import { AdminPerson } from "../components/types";

interface Action {
    payload: AdminPerson,
    type: `update_admin`
}


const initial_state: {} | AdminPerson = {}

function updateAdmin(payload: AdminPerson): Action {
    return (
        {
            type: `update_admin`,
            payload
        }
    )
}


function AdminReducer(state = initial_state, action: Action): (AdminPerson | {}) {
    if (action.type == `update_admin`) return action.payload
    else
        return state
}

export const selectAdmin = (state:any) => state.AdminReducer

export  {updateAdmin};

export default AdminReducer



