import {
  IonBackButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonPage,
  IonRow,
  IonText,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import React from "react";

const Choice: React.FC = () => {
  return (
    <IonPage>
      <IonContent>
        <div className="container ion-text-center ion-padding-top ion-margin-top">
          <IonToolbar>
            <IonButtons slot="start">
              <IonBackButton defaultHref="/home"></IonBackButton>
            </IonButtons>
          </IonToolbar>
          <IonTitle>Choose Catergory</IonTitle>
          <IonGrid>
            <IonRow>
              <IonCol>
                <IonCard button routerLink="/create/questions" className="ion-padding" color="primary">
                  <IonCardHeader>
                      <IonCardTitle>Upload Questions</IonCardTitle>
                  </IonCardHeader>
                </IonCard>
              </IonCol>
              <IonCol>
                <IonCard button routerLink="/create/solutions" className="ion-padding" color="dark">
                  <IonCardHeader>
                      <IonCardTitle>Upload Solutions</IonCardTitle>
                  </IonCardHeader>
                </IonCard>
              </IonCol>
            </IonRow>
          </IonGrid>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Choice;
