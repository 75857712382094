
export function tryCatch(func: Function) {

    try {
        func()
    } catch (err) {
        alert(err)
        console.log(err)
    }

}