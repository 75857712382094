import { Person } from "../components/types";

interface Action {
    payload: any,
    type: `update`
}


const initial_state: {} | Person = {}

function updateUser(payload: Person): Action {
    return (
        {
            type: `update`,
            payload
        }
    )
}


function userReducer(state = initial_state, action: Action): (Person | {}) {
    if (action.type == `update`) return action.payload
    else
        return state
}

export const selectUser = (state:any) => state.userReducer

export  {updateUser};

export default userReducer



