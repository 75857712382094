import {
  IonAvatar,
  IonBadge,
  IonButton,
  IonButtons,
  IonCard,
  IonCardHeader,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonImg,
  IonMenuButton,
  IonPage,
  IonRippleEffect,
  IonRow,
  IonSpinner,
  IonTitle,
  IonToolbar,
  useIonViewWillEnter,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import shape from "../images/p2.jpg";
import { logOut } from "ionicons/icons";
import { auth, fstore } from "../../../../Firebase";
import { tryCatch } from "../../../../Firebase/admin";
import {
  AdminPerson,
  QDocument,
  QPaymentRequest,
  UserDocument,
} from "../../../../components/types";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { selectAdmin, updateAdmin } from "../../../../State/adminState";
import { getLocalAdmin } from "../../../../State/local";
import { Storage } from "@capacitor/storage";

const ReviewAdminHome: React.FC = () => {
  const [papers, setpapers] = useState<QDocument[]>([]);
  const [pending, setpending] = useState<UserDocument[]>([]);
  const [rejected, setrejected] = useState<UserDocument[]>([]);
  const [approved, setapproved] = useState<UserDocument[]>([]);
  const [paymentRequests, setpaymentRequests] = useState<QPaymentRequest[]>([]);
  const [loading, setloading] = useState(false);
  const admin: AdminPerson = useSelector(selectAdmin);
  const dispatch = useDispatch();
  const history = useHistory();

  async function initAdmin() {
    if (!admin.email) {
      const p = await getLocalAdmin();
      console.log(p);
      if (p) {
        dispatch(updateAdmin(p));
      } else {
        history.push(`/review/login`);
      }
    }
  }

  useIonViewWillEnter(() => {
    initAdmin();
  });
  useEffect(() => {
    getApproved();
    getRejected();
    getPending();
    // getPaymentRequest()
  }, []);

  function getPapers() {
    tryCatch(async () => {
      setloading(true);
      fstore
        .collection(`faculties`)
        .doc(`FET`)
        .collection(`approved`)
        .onSnapshot((documents) => {
          const docs: any[] = documents.docs.map((paper) => {
            let t: any[] = [];
            if (paper.data().solution === true) {
              t.push(paper.data());
            }
            return t;
          }); // will return only question papers
          setpapers(docs);
          setloading(false);
        });
    });
  }

  async function getPending() {
    setloading(true);
    fstore
      .collection(`faculties`)
      .doc(`FET`)
      .collection(`pending`)
      .onSnapshot((snapshot) => {
        const docs: any[] = snapshot.docs.map((doc) => {
          let t: any[] = [];
          if (doc.data().solution) {
            t.push(doc.data());
          } 
          return t;
        });   
        console.log(docs[0])

        if(docs[0].temp){
         setpending([])
         setloading(false);
        }else{
          setpending(docs[0])
          setloading(false);
        } 
      });
  }

  async function getRejected() {
    setloading(true);
    fstore
      .collection(`faculties`)
      .doc(`FET`)
      .collection(`rejectedSolutions`)
      .onSnapshot((snapshot) => {
        const docs: any[] = snapshot.docs.map((doc) => doc.data());
        setrejected(docs);
        setloading(false);
      });
  }

  async function getApproved() {
    setloading(true);
    fstore
      .collection(`faculties`)
      .doc(`FET`)
      .collection(`approvedSolutions`)
      .onSnapshot((snapshot) => {
        const docs: any[] = snapshot.docs.map((doc) => doc.data());
        setapproved(docs);
        setloading(false);
      });
  }

  useEffect(() => {
    getPapers();
  }, []);

  async function getPaymentRequest() {
    setloading(true);
    fstore
      .collection(`faculties`)
      .doc(`FET`)
      .collection(`payment-requests`)
      .onSnapshot((snapshot) => {
        const docs: any[] = snapshot.docs.map((doc) => doc.data());
        setpaymentRequests([...docs]);
        setloading(false);
      });
  }
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="dark">
          <IonButtons slot="start">
            <IonMenuButton menu="review"></IonMenuButton>
          </IonButtons>
          <IonTitle slot="start">QCP Review Admin</IonTitle>
          <IonButton routerLink={`/review/papers`} fill={`clear`} slot={`end`}>
            papers{" "}
            <IonBadge className={`ion-margin-start`} color={`tertiary`}>
              {papers.length}
            </IonBadge>
          </IonButton>
          {!loading ? (
            <IonAvatar slot={"end"} className="p-2 ">
              {admin.photoURL && (
                <img
                  src={admin.photoURL}
                  className="d-sm-block d-xs-none d-lg-block d-md-block"
                ></img>
              )}
            </IonAvatar>
          ) : (
            <IonSpinner></IonSpinner>
          )}
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonGrid className="container-lg">
          <IonRow className="container-fluid ion-justify-content-center">
            <IonCol sizeLg="6">
              <IonCard
                button
                onClick={() => history.push(`/review/approved`, approved)}
                className="w-100"
                color="tertiary"
              >
                <IonCardHeader>
                  <IonToolbar color="tertiary">
                    <IonTitle className="text-center">Approved Papers</IonTitle>
                  </IonToolbar>
                  <IonToolbar color="tertiary">
                    <IonTitle className="text-center h1 display-1">
                      {" "}
                      {loading && <IonSpinner></IonSpinner>}{" "}
                      {!loading ? approved.length : ``}
                    </IonTitle>
                  </IonToolbar>
                </IonCardHeader>
              </IonCard>
            </IonCol>
            <IonCol sizeLg="6">
              <IonCard
                className="w-100"
                color="warning"
                onClick={() => history.push(`/review/pending`, pending)}
              >
                <IonCardHeader>
                  <IonToolbar color="warning">
                    <IonTitle className="text-center">Pending Reviews</IonTitle>
                  </IonToolbar>
                  <IonToolbar color="warning">
                    <IonTitle className="text-center h1 display-1">
                      {loading && <IonSpinner></IonSpinner>}{" "}
                      {!loading ? pending.length: ``}
                    </IonTitle>
                  </IonToolbar>
                </IonCardHeader>
              </IonCard>
            </IonCol>
            <IonCol sizeLg="6">
              <IonCard
                onClick={() => history.push(`/review/rejected`, rejected)}
                className="w-100"
                color="danger"
                button
              >
                <IonCardHeader>
                  <IonToolbar color="danger">
                    <IonTitle className="text-center">Rejected Papers</IonTitle>
                  </IonToolbar>
                  <IonToolbar color="danger">
                    <IonTitle className="text-center h1 display-1">
                      {" "}
                      {loading && <IonSpinner></IonSpinner>}{" "}
                      {!loading ? rejected.length : ``}
                    </IonTitle>
                  </IonToolbar>
                </IonCardHeader>
              </IonCard>
            </IonCol>
            {/* <IonCol sizeLg="6">
              <IonCard className="w-100" color="success" onClick={() => history.push("/review/payment-requests", paymentRequests)} >
                <IonCardHeader>
                  <IonToolbar color="success">
                    <IonTitle className="text-center">
                      Payment Requests
                    </IonTitle>
                  </IonToolbar>
                  <IonToolbar color="success">
                    <IonTitle className="text-center h1 display-1">
                      {loading && <IonSpinner></IonSpinner>} {!loading ? paymentRequests.length : ``}
                    </IonTitle>
                  </IonToolbar>
                </IonCardHeader>
              </IonCard>
            </IonCol> */}
            <IonCol sizeLg="12">
              <IonCard
                className="w-100"
                color="medium"
                onClick={() => {
                  history.push(`/review/login`);
                  Storage.remove({ key: `admin` });
                  auth.signOut();
                }}
              >
                <IonCardHeader>
                  <IonToolbar color="medium">
                    <IonTitle className="text-center">Logout</IonTitle>
                  </IonToolbar>
                  <IonToolbar color="medium">
                    <IonTitle className="text-center  display-1">
                      <IonIcon icon={logOut}></IonIcon>
                    </IonTitle>
                  </IonToolbar>
                </IonCardHeader>
                <IonRippleEffect type="unbounded"></IonRippleEffect>
              </IonCard>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default ReviewAdminHome;
