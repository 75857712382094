import {
  IonAvatar,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCheckbox,
  IonCol,
  IonContent,
  IonFab,
  IonFabButton,
  IonFooter,
  IonGrid,
  IonHeader,
  IonIcon,
  IonImg,
  IonItem,
  IonItemDivider,
  IonItemOption,
  IonItemOptions,
  IonLabel,
  IonLoading,
  IonMenuButton,
  IonModal,
  IonNote,
  IonPage,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonTitle,
  IonToast,
  IonToolbar,
} from "@ionic/react";
import { info, timeStamp } from "console";
import {
  arrowBack,
  chevronForward,
  close,
  information,
  informationCircle,
  trash,
} from "ionicons/icons";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import {
  AdminPerson,
  Person,
  QDocument,
  Uploader,
  UserDocument,
} from "../../../../components/types";
import { fstore } from "../../../../Firebase";
import { selectAdmin } from "../../../../State/adminState";
import ApprovedCard from "../components/ApprovedCard";

const UnAssigned: React.FC = () => {
  const location = useLocation();
  const [unassigned, setUnassigned] = useState<UserDocument[]>([]);
  const admin: AdminPerson = useSelector(selectAdmin);
  const [selected, setselected] = useState<any[]>([]);
  const [people, setpeople] = useState<Person[]>([]);
  const [confirmSelectionModal, setConfirmSelectionModal] = useState(false);
  const [uploaderID, setUploaderID] = useState("");
  const [loading, setloading] = useState(false);
  const [successful, setsuccessful] = useState(false);
  const selectUploaderRef = useRef<HTMLIonSelectElement>(null);
  const [uploaders, setUploaders] = useState<Uploader[]>([]);
  const history = useHistory();

  function updateAssignedPapers(papers: UserDocument[]) {
    papers.map((paper) => {
      fstore
        .collection(`faculties`)
        .doc(`FET`)
        .collection(`assigned`)
        .doc(paper.id)
        .set(paper);
    });
  }
  async function updateUnAssignedPapers(papers: UserDocument[]) {
    papers.map((paper) => {
      fstore
        .collection(`faculties`)
        .doc(`FET`)
        .collection(`unassigned`)
        .doc(paper.id)
        .delete();
    });
  }

  function updatePaperAssignedValue() {
    let tem = selected;
    let temp = tem.map((t) => {
      t.assigned = true;
      t.assignedTo = selectUploaderRef.current?.value;
      return t;
    });
    setselected(temp);
  }

  function assignPapers() {
    setloading(true);

    // update all selected values .assigned property
    updatePaperAssignedValue();

    // collect PersonInfo
    let personInfo = {
      ...people.filter((person) => person.email === uploaderID),
    };

    // check if an uploader with the selected ID already exists
    fstore
      .collection("uploader")
      .doc(uploaderID)
      .get()
      .then((doc) => {
        if (doc.exists) {
          selected.map((document) => {
            fstore
              .collection("uploader")
              .doc(uploaderID)
              .collection("papers")
              .doc(document.id)
              .set(document)
              .then(() => {
                updateAssignedPapers(selected);
                updateUnAssignedPapers(selected);
                setConfirmSelectionModal(false);
                setloading(false);
                setsuccessful(true);
              });
          });
        } else {
          // create the collection papers and set the initial papers
          fstore
            .collection("uploader")
            .doc(uploaderID)
            .set(personInfo[0])
            .then(() => {
              selected.map((document) => {
                fstore
                  .collection("uploader")
                  .doc(uploaderID)
                  .collection("papers")
                  .doc(document.id)
                  .set(document)
                  .then(() => {
                    updateAssignedPapers(selected);
                    updateUnAssignedPapers(selected);
                    setConfirmSelectionModal(false);
                    setloading(false);
                    setsuccessful(true);
                  })
                  .catch((err) => {
                    console.log(err);
                    alert("Assignment Error, Visit Console");
                  });
              });
            });
        }
      });
  }

  function getPeople() {
    fstore.collection("people").onSnapshot((documents) => {
      let DOCS: any[] = documents.docs.map((doc) => doc.data());
      setpeople(DOCS);
    });
  }

  useEffect(() => {
    if (location.state) {
      const temp: any = location.state;
      setUnassigned(temp.unassigned);
      setUploaders(temp.uploader);
    }
    getPeople();
  }, [location]);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color={`dark`} className="">
          <IonButtons slot="start">
            <IonMenuButton menu="admin"></IonMenuButton>
          </IonButtons>
          <IonTitle slot="start">Unassigned Papers</IonTitle>
          <IonAvatar slot={"end"} className="p-2">
            {admin.photoURL && <IonImg src={admin.photoURL}></IonImg>}
          </IonAvatar>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className="container">
          <div className="container-fluid">
            {unassigned.map((paper, index) => {
              return (
                <IonCard key={index}>
                  <IonItem lines="none">
                    <IonCardHeader>
                      <IonCardTitle>
                        {paper.code} {paper.title}{" "}
                      </IonCardTitle>
                      <IonCardSubtitle>
                        {paper.year} {paper.type}
                      </IonCardSubtitle>
                    </IonCardHeader>
                    {/* <IonLabel slot="start">
                      </IonLabel> */}
                    <IonCheckbox
                      slot="end"
                      color="success"
                      onIonChange={(e) => {
                        if (e.detail.checked) {
                          setselected([...selected, paper]);
                        } else {
                          const temp = selected;
                          setselected([
                            ...temp.filter((pap) => paper.id != pap.id),
                          ]);
                        }
                      }}
                      //   onIonChange={(e) => {
                      //     compilePapers(e.detail.checked, index, paper.id);
                      //   }}
                    ></IonCheckbox>
                  </IonItem>
                </IonCard>
              );
            })}
          </div>
          <div>
            {location.state ? (
              " "
            ) : (
              <IonCard mode="ios">
                <IonCardContent className="ion-text-center">
                  <IonCardTitle>No Unassigned Papers</IonCardTitle>
                </IonCardContent>
              </IonCard>
            )}
          </div>
        </div>
      </IonContent>
      {selected.length > 0 && (
        <IonFab
          vertical="bottom"
          horizontal="end"
          slot="end"
          onClick={() => {
            setConfirmSelectionModal(true);
          }}
        >
          <IonButton
            shape="round"
            onClick={() => {
              setConfirmSelectionModal(true);
            }}
          >
            continue <IonIcon icon={chevronForward} slot="end"></IonIcon>
          </IonButton>
        </IonFab>
      )}

      <IonModal
        isOpen={confirmSelectionModal}
        onDidDismiss={() => {
          setConfirmSelectionModal(false);
        }}
      >
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonButton
                onClick={() => {
                  setConfirmSelectionModal(false);
                }}
              >
                <IonIcon
                  slot="icon-only"
                  icon={arrowBack}
                  color="success"
                ></IonIcon>
              </IonButton>
            </IonButtons>
            <IonTitle slot="start">Confirm Selection</IonTitle>

            <IonButton
              slot="end"
              onClick={() => {
                assignPapers();
              }}
            >
              Assign
            </IonButton>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonItem className="ion-margin" fill="outline" shape="round">
            <IonLabel position="floating">Select Uploader</IonLabel>
            <IonSelect
              ref={selectUploaderRef}
              onIonChange={(e) => setUploaderID(e.detail.value)}
            >
              {people.map((person, index) => {
                return (
                  <IonSelectOption key={index} value={person.email}>
                    {person.name}
                    {/* <IonNote>{person.email}</IonNote> */}
                  </IonSelectOption>
                );
              })}
            </IonSelect>
          </IonItem>

          {selected.map((paper, index) => {
            return (
              <IonItem lines="full" key={index}>
                <IonCardHeader>
                  <IonCardTitle>
                    {paper.code} {paper.title}{" "}
                  </IonCardTitle>
                  <IonCardSubtitle>
                    {paper.year} {paper.type}
                  </IonCardSubtitle>
                </IonCardHeader>
                <IonButtons slot="end">
                  <IonButton
                    color="danger"
                    onClick={() => {
                      const temp = selected;
                      setselected([
                        ...temp.filter((pap) => paper.id != pap.id),
                      ]);
                    }}
                  >
                    <IonIcon icon={trash} slot="icon-only"></IonIcon>
                  </IonButton>
                </IonButtons>
              </IonItem>
            );
          })}
          <IonToolbar className="ion-margin-vertical"></IonToolbar>
          <IonLoading
            isOpen={loading}
            onDidDismiss={() => {
              setloading(false);
            }}
          ></IonLoading>
          <IonToast
            isOpen={successful}
            message="Process Successful"
            icon={informationCircle}
            color="secondary"
            duration={3000}
            onDidDismiss={() => {
              setsuccessful(false);
              history.goBack();
            }}
          ></IonToast>
        </IonContent>
      </IonModal>
    </IonPage>
  );
};

export default UnAssigned;
