import { IonAvatar, IonButton, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCol, IonContent, IonGrid, IonHeader, IonImg, IonInput, IonItem, IonLabel, IonLoading, IonModal, IonPage, IonPopover, IonRow, IonSelect, IonSelectOption, IonTitle, IonToolbar } from '@ionic/react'
import React, { useState } from 'react'
import { app, auth, fstore } from '../../../Firebase';
import "../../../style/Auth.css";
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import firebase from 'firebase';
import { Person } from '../../../components/types';
import { getPerson } from '../../../Firebase/login';
import { useDispatch } from 'react-redux';
import { updateUser } from '../../../State/userstate';
import AddDetailModal from '../../../components/AddDetailModal';
import { saveUser } from '../../../State/local';
import { useHistory } from 'react-router';

export const logo = `https://quesers-app.web.app/static/media/logo2.35a9dc7f.png`;


function Login() {
    const [addDetail, setaddDetail] = useState(false)
    const [loading, setloading] = useState(false)

    const dispatch = useDispatch();
    const history = useHistory();


    const uiConfig = {
        // Popup signin flow rather than redirect flow.
        signInFlow: 'popup',
        // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
        signInSuccessUrl: '/signedIn',
        // We will display Google and Facebook as auth providers.
        callbacks: {
            // Avoid redirects after sign-in.
            signInSuccessWithAuthResult: (a1: { user: firebase.User, credential: any, operationType: string, }) => {
                signInUser(a1.user)

                return false
            },
        },
        signInOptions: [
            firebase.auth.GoogleAuthProvider.PROVIDER_ID,
            // firebase.auth.FacebookAuthProvider.PROVIDER_ID,
        ],
    };


    async function signInUser(user: firebase.User) {
        if (user.email) {
            setloading(true)
            const person: undefined | Person = await getPerson(user.email);
            if (person) {
                dispatch(updateUser(person))
                saveUser(person)
                const a = document.createElement('a')
                a.href='/home'
                a.click();
            }
            else {
                const person: Person = {
                    email: user.email,
                    name: user.displayName || ``,
                    photoURL: user.photoURL || ``,
                }
                dispatch(updateUser(person))
                setaddDetail(true)
            }
        }
        setloading(false)
    }


    return (
        <IonPage className={`auth`}>
            <IonContent>
                <IonToolbar className={`content-toolbar`} color={`dark`}>
                    <IonGrid>
                        <IonRow>
                            <IonCol>
                                <div style={{ height: `10vh` }}></div>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol></IonCol>
                            <IonCol size={`12`} sizeMd={`6`} sizeLg={`6`}>
                                <IonCard color={`dark`} mode={`ios`} className={`login-hero-card `} >
                                    <IonCardHeader>
                                        <IonImg className={`login-logo`} src={logo}></IonImg>
                                    </IonCardHeader>
                                    <IonCardContent mode={`md`} className={`login-content`}>
                                        <h1>Quesers Community Platform</h1>
                                        <br />
                                        <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />
                                        <br />
                                        <br />
                                        <br />
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>
                            <IonCol></IonCol>
                        </IonRow>
                    </IonGrid>
                </IonToolbar>
                 <IonLoading onDidDismiss={()=>setloading(false)} isOpen={loading} message={`Hold on Quesereth`}></IonLoading>
                <AddDetailModal onDidDismiss={() => setaddDetail(false)} isOpen={addDetail}>
                </AddDetailModal>
            </IonContent>

        </IonPage>
    )
}

export default Login
