import { IonItem, IonCardSubtitle, IonRippleEffect, IonButtons, IonButton, IonSpinner } from '@ionic/react'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { QDocument } from '../../../../components/types'
import { fstore } from '../../../../Firebase'
import { tryCatch } from '../../../../Firebase/admin'

const  AvailablePaper:React.FC<{res:QDocument,edit:()=>void}>= function({res,edit}) {
    const [loading, setloading] = useState(false)

    async function deletePaper(){
        setloading(true)
        tryCatch(()=>{
            fstore.collection(`faculties`).doc(`FET`).collection(`papers`).doc(res.id).delete()
        })
        setloading(false);
    }
    return (
        <div className={`ion-margin-bottom container-fluid card`}>
            <IonItem disabled={loading} lines={`none`} color={`none`}>
                <IonCardSubtitle> {res.code}  {res.title} {res.type} {res.year}</IonCardSubtitle>

                <IonRippleEffect></IonRippleEffect>
                <IonButtons slot={`end`}>
                    <IonButton onClick={() => edit()} color={`success`} mode={`ios`} > <Link to={`#`} >Edit</Link ></IonButton>
                    <IonButton onClick={() => deletePaper()} color={`success`} mode={`ios`} >  <Link to={`#`}>Delete</Link></IonButton>
                </IonButtons>
                {
                    loading&&<IonSpinner></IonSpinner>
                }
            </IonItem>
        </div>
    )
}

export default AvailablePaper
