import {
  IonAvatar,
  IonButtons,
  IonContent,
  IonHeader,
  IonImg,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { UserDocument } from "../../../../components/types";
import RejectCard from "../components/RejectCard";
import shape from "../images/p2.jpg";

const Rejected: React.FC = () => {

  const location = useLocation()
  const [rejected, setrejected] = useState<UserDocument[]>([])


  useEffect(() => {
    if (location.state) {
      const temp: any = location.state;
      setrejected(temp)
    }
  }, [location])
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="dark">
          <IonButtons slot="start">
            <IonMenuButton menu="admin"></IonMenuButton>
          </IonButtons>
          <IonTitle slot="start">Rejected Papers</IonTitle>
          <IonAvatar slot={"end"} className="p-2">
            <IonImg src={shape}></IonImg>
          </IonAvatar>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className="container">
          <div className="container-fluid">
            {
              rejected.map((paper, i) => {
                return (
                  <RejectCard key={i} paper={paper}></RejectCard>
                )
              })
            }
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Rejected;
