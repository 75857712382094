import { IonAvatar, IonBackButton, IonButton, IonButtons, IonCard, IonCardSubtitle, IonCardTitle, IonCheckbox, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonImg, IonItem, IonLabel, IonMenuButton, IonNote, IonPage, IonProgressBar, IonRippleEffect, IonRow, IonSearchbar,  IonSelect, IonSelectOption, IonTitle, IonToolbar } from '@ionic/react'
import { chevronForward, filter } from 'ionicons/icons'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { Person, QDocument } from '../../../../components/types'
import { fstore } from '../../../../Firebase'
import { tryCatch } from '../../../../Firebase/admin'
import { selectUser } from '../../../../State/userstate'
import { reverseMatch } from '../../user/UploadPaper' 
import { sample_image } from '../../user/UploadTaskPage'
import AddModal from '../components/AddModal'
import AvailablePaper from '../components/AvailablePaper'

const search_image = `http://media.tumblr.com/d1882c631fe517b4bb0bd3868022cd53/tumblr_inline_mk89bd0ND91qz4rgp.jpg`

function AdminPapers() {
    const user: Person = useSelector(selectUser)
    const [phrase, setphrase] = useState(``)
    const [searching, setsearching] = useState(false)
    const [addPaper, setaddPaper] = useState(false)
    const [editDoc, seteditDoc] = useState<QDocument | undefined>()
    const [papers, setpapers] = useState<QDocument[]>([])
    const [pagination, setpagination] = useState<number>(0)
    const [visible_papers, setvisible_papers] = useState<QDocument[]>([])
    const [semester, setsemester] = useState<1|2>(2);
    const history = useHistory()

    function getPapers() {
        tryCatch(async () => {
            setsearching(true)

            fstore.collection(`faculties`).doc(`FET`).collection(`papers`).onSnapshot((documents) => {
                const docs: any[] = documents.docs.map((paper) => paper.data())
                setpapers([...docs]);
                setvisible_papers([...docs.filter((doc, index) => { return (index >= pagination && index <= (pagination + 100)) })]);
                setsearching(false)


            })

        })

    }

    function filterPhrase(phrase: string) {
        phrase = phrase.toLowerCase()
        const words = phrase.split(` `)

        if (!phrase) {
            setvisible_papers(papers)
            return;
        }

        let p = papers
        p = p.filter(paper => {
            if (reverseMatch(paper.code.toLowerCase(), phrase) || reverseMatch(paper.title.toLowerCase(), phrase) || reverseMatch(paper.type.toLowerCase(), phrase)) {
                return true
            }
            return false
        })
        // p.sort((a, b) => {
        //     let pa = reverseMatch(a.code.toLowerCase(), phrase) ? 1 : 0;
        //     pa += reverseMatch(a.title.toLowerCase(), phrase) ? 1 : 0;
        //     pa += reverseMatch(a.type.toLowerCase(), phrase) ? 1 : 0;
        //     pa += reverseMatch(a.year.toLowerCase(), phrase) ? 1 : 0;

        //     let pb = reverseMatch(b.code.toLowerCase(), phrase) ? 1 : 0;
        //     pb += reverseMatch(b.title.toLowerCase(), phrase) ? 1 : 0;
        //     pb += reverseMatch(b.type.toLowerCase(), phrase) ? 1 : 0;
        //     pb += reverseMatch(b.year.toLowerCase(), phrase) ? 1 : 0;

        //     return - pa + pb
        // })

        setvisible_papers([...p])


    }

    useEffect(() => {
        getPapers()

    }, [])


    function editPaper(pap: QDocument) {
        seteditDoc(pap)
        setaddPaper(true)
    }

    useEffect(() => {
        setvisible_papers([...papers.filter((doc, index) => { return (index >= pagination && index <= (pagination + 100)) })]);
    }, [pagination])


    return (
        <IonPage>
            <IonHeader>
                <IonToolbar color={`dark`}>
                    <IonButtons slot='start'>

                        <IonBackButton defaultHref={"/home"}></IonBackButton>
                    </IonButtons>
                    <IonTitle>
                        Upload New
                    </IonTitle>
                    <IonSelect>
                        <IonSelectOption value={semester}>First Semester</IonSelectOption>
                        <IonSelectOption value={semester}>Second Semester</IonSelectOption>
                    </IonSelect>
                    <IonAvatar style={{ transform: `scale(0.8)` }} slot={`end`}>
                        {user.photoURL ? <IonImg src={user.photoURL}></IonImg> : ``}
                    </IonAvatar>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonToolbar>
                    <IonGrid>
                        <IonRow>
                            <IonCol></IonCol>
                            <IonCol size={`12`} sizeSm={`11`} sizeMd={`8`} sizeLg={`6`} sizeXl={`5`} >
                                <IonSearchbar placeholder={`filter by title`} searchIcon={filter} onIonChange={e => { setphrase(e.detail.value || ``); filterPhrase(e.detail.value || ``) }} value={phrase} color={`light`}></IonSearchbar>
                                {searching && <IonProgressBar type={`indeterminate`} style={{ borderRadius: `20px` }} color={`success`}></IonProgressBar>}
                                <br />
                                <small> <i> search phrase <b>'{phrase}'</b> </i></small>
                                <br />
                            </IonCol>
                            <IonCol></IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol></IonCol>
                            <IonCol sizeSm={`11`} sizeMd={`9`} sizeLg={`8`} sizeXl={`7`} size={`12`}>
                                {visible_papers.map((res, i) => {
                                    return (
                                        <AvailablePaper key={i} res={res} edit={() => editPaper(res)}  ></AvailablePaper>
                                    )
                                })}
                                <div className={`text-center`}>
                                    {papers.length <= 0 && <img style={{ margin: `auto`, opacity: `0.3` }} src={search_image}></img>}
                                </div>
                                <IonToolbar>
                                    <IonRow>
                                        <IonCol></IonCol>
                                        <IonCol>
                                            <IonButtons >
                                                {
                                                    Array.from(Array(Math.ceil(papers.length / 100)).keys()).map((el, i) => {
                                                        const index = i + 1;
                                                        return (
                                                            <IonButton onClick={() => setpagination((index -1) * 100)} key={index} disabled={Math.floor((pagination + 100) / 100) == index} color={Math.floor((pagination + 100) / 100) == index ? `success` : `dark`} fill={Math.floor((pagination + 100) / 100) == index ? `outline` : `clear`}>
                                                                {index}
                                                            </IonButton>
                                                        )
                                                    })
                                                }

                                            </IonButtons>
                                        </IonCol>
                                        <IonCol>
                                            <IonToolbar>
                                                <IonButtons slot={`end`} >
                                                    <IonNote>
                                                        <small>
                                                            {100} of {papers.length}
                                                        </small>
                                                    </IonNote>
                                                </IonButtons>
                                            </IonToolbar>
                                        </IonCol>
                                    </IonRow>
                                </IonToolbar>
                                <IonToolbar>
                                    <IonButtons slot={`end`}>
                                        <IonButton size={`large`} fill={`solid`} onClick={() => setaddPaper(true)} color={`dark`}>
                                            <IonLabel >ADD</IonLabel>
                                        </IonButton>
                                    </IonButtons>
                                </IonToolbar>
                                <br />
                                <br />
                                <br />

                            </IonCol>
                            <IonCol></IonCol>
                        </IonRow>
                    </IonGrid>
                    <AddModal doc={editDoc} onDidDismiss={() => { setaddPaper(false); seteditDoc(undefined) }} isOpen={addPaper}></AddModal>
                </IonToolbar>
            </IonContent>
        </IonPage>
    )
}

export default AdminPapers
